<template>
    <div class="top" :class="{'internal' : !isMainPage}">
        <div class="header__wrapper">
            <div class="header">
                <div class="header__logo" 
                    @click="goMain"></div>
                <top-menu @toggleMainBanner="toggleMainBanner"></top-menu>            
            </div> 
        </div>        
        <div class="main__banner" v-if="isMainPage"
            :class="{'shifted': isMainBannerShifted}">
            <div :class="{'main-banner__inside': true}">
                <div>
                    <div>
                        <h1><span>Climate CRON</span><br/>"Together we create the future today!"</h1>
                        <router-link :to="{ name: 'login'}" class="register-main" v-if="!$store.state.user.status">Register now</router-link>
                        <router-link :to="{ name: 'personal'}" class="register-main" v-else>My account</router-link>
                    </div>

                    <div class="center">
                        <div class="article internal">  
                            <div class="platform">
                                <div class="widget-absolute" v-if="false">
                                    <orders :type="2" :user_id="null" :title="'Last orders'" :guest="true"></orders>
                                </div>
                                <div class="custom-announce">
                                    <h2 style="color: white; text-align: left; margin-bottom: 20px; margin-top: 20px;   text-shadow: 1px 1px 0px #000;">Real Estate with "Climate Cron"</h2>
                                    <ol style="font-size: 14px; color: #fff; line-height: 20px; padding-right: 10px;">
                                        <li>Real estate purchase and sale transactions using Climate Cron are tax-free (5% VAT + 9% corporate tax) for UAE market.</li>
                                        <li style="margin-top: 6px;">Individual discounts of up to 10% of sales volume when conducting transactions using Climate Cron.</li>
                                        <li style="margin-top: 6px;">Climate Cron redemption guarantee in 365 days, fixed in the contract, at the price at which they were purchased by investors.</li>
                                        <li style="margin-top: 6px;">According to clause 3, the investor is insured against the risks inherent in other fiat currencies: depreciation, inflation, exchange rate differences, etc.</li>
                                        <li style="margin-top: 6px;">By purchasing Climate Cron, the investor actually fixes for himself the value of real estate for the future. If desired, he can later purchase it for the existing Climate Cron, or sell them at market value.</li>
                                        <li style="margin-top: 6px;">The rapidly expanding real estate market in the UAE has a steady demand from investors, which affects the value of real estate - it is constantly growing. Which, in turn, leads to an increase in the cost of each purchased Climate Cron.</li>
                                        <li style="margin-top: 6px;">Climate Cron transactions are possible in any country, even with the limitations of the global financial market. </li>
                                    </ol>
                                </div>
                                <!-- <div class="custom-announce" style="text-align: left;">
                                    <p class="custom-header" style="font-weight: 400;">"Climate CRON" is now available for trade on "Mercatox Exchange":<br/>
                                        <a href="https://mercatox.com/exchange/CRON/BTC" target="_blank" style="font-style: italic">CRON/BTC</a> and
                                        <a href="https://mercatox.com/exchange/CRON/ETH" target="_blank" style="font-style: italic">CRON/ETH</a>
                                    </p>
                                    <p style="text-align: center; font-size: 14px; line-height: 22px;">
                                        <a href="https://www.huobi.com/" style="color: #f5bc00;">"Huobi Global"</a>,
                                        <a href="https://hitbtc.com/" style="color: #f5bc00;">"HitBTC"</a> and
                                        <a href="https://www.probit.com/" style="color: #f5bc00;">"Probit Exchange"</a> approved listing of "Climate CRON" on their markets and trading is starting soon.</p>
                                </div> -->
                                <!-- <div class="custom-announce">
                                    <p style="font-size: 18px; line-height: 24px; color:#fff;">The Government of Sindh (Pakistan) acquired 200.000 Climate Crons</p>
                                    <p style="color: #ccc; font-style: italic; margin-top: -10px; display: flex; justify-content: space-between;">News: March 17, 2021<a href="https://climatecron.com/articles/government-of-sindh-pakistan-purchased-200-000-climate-cron" style="color:#fff;">Watch documents</a></p>
                                </div>       -->
                                <!-- <div class="custom-announce">
                                    <p style="font-size: 18px; line-height: 24px; color:#fff;">"NBB TC Holding" purchased 5.000 Climate Crons</p>
                                    <p style="color: #ccc; font-style: italic; margin-top: -10px; display: flex; justify-content: space-between;">News: February 8, 2021<a href="https://climatecron.com/news/nbb-tc-holding-purchased-5000-climate-cron" style="color:#fff;">Watch documents</a></p>
                                </div>                                                                                      -->
                                <!-- <div class="custom-announce" style="background: #F5BC00;">
                                    <p style="font-size: 14px; color:#000; line-height: 20px; font-weight: 400;">The Environment, Climate Change and Coastal Development Sindh has signed an agreement with UAE-based Climate Global Control Trading to work together on water security and climate change.</p>
                                    <p style="color: #525252; font-style: italic; margin-top: -10px; display: flex; justify-content: space-between;">News: March 17, 2021<a style="color: black" href="https://climatecron.com/news/uae-company-environment-dept-ink-accord">Read more</a></p>
                                </div>   -->
                                <div class="etherium" style="margin-top: 15px">
                                    <p><a href="https://etherscan.io/token/0xd88d0ca1b74b4f18c3721b43a19a55a9fc56ce87"><span>Etherscan.io</span></a></p>
                                </div>         
                                 <!-- <a class="twitter-timeline" data-height="250" data-width="100%" data-theme="light" href="https://twitter.com/ClimateCron?ref_src=twsrc%5Etfw">Tweets by ClimateCron</a> -->

                                <!-- <div class="custom-announce">
                                    <p style="text-align: center; font-size: 13px; line-height: 20px;"><a href="https://climatecron.com/news/egypt-ethiopia-dispute-over-giant-nile-dam-reaches-critical-point" style="color:#fff;">"The water crisis is the biggest crisis that nobody talks about. Its consequences are clear — food insecurity, financial stability, conflict and migration," - <span style="font-weight: 300;">says Andrew steer, CEO of WRI</span>.</a></p>
                                </div>   -->
                            </div>
                        </div>
                    </div>   

                    <div class="intro-video" style="min-height: 405px;">
                        <div class="loader__wrapper">
                            <h2 style="
                                line-height: 34px;
                                color: white;
                                font-size: 26px;
                                font-weight: 300;
                                margin: 0 0 15px;
                            ">
                                Why to purchase now
                            </h2>
                            <div v-if="false" class="loader">
                                <span style="font-size: 40px; line-height: 40px; font-weight: 700;">6.435</span>
                                <span style="font-size: 16px; line-height: 18px; font-weight: 300; text-transform: uppercase; text-align: center;">Climate Cron</span>
                                <span style="font-size: 25px; font-weight: 300; text-transform: uppercase; text-align: center; line-height: 18px; margin-top: 3px;">only for</span>
                                <span style="font-size: 16px; font-weight: 400; text-transform: uppercase; text-align: center;">Private Investors</span>
                            </div>
                            <ul style="text-align: left; color: #fff; padding-left: 20px;">
                                <li style="margin-top: 6px;">A limited number of "Climate Cron" tokens for private investors.</li>
                                <li style="margin-top: 6px;">"Climate Cron" is a payment instrument for the governments of countries in order to carry out import-export transactions, as well as projects related to the climate and water security of the participating countries of the project.</li>
                                <li style="margin-top: 6px;">Stability and provision of "Climate Cron" is guaranteed by real estate objects of the Emirates and projects in the countries participating in the "Climate Cron" project.</li>
                            </ul>
                            
                            <router-link
                                :to="{ name: 'presale'}"
                                class="presale__preorder"
                                style="background-color: #F5BC00; color: #000; margin-bottom: 15px;"
                            >
                                Purchase
                            </router-link>
                        </div>
                        <!-- <video width="720" height="405" controls="controls" poster="media/video/cover.jpg">
                            <source src="media/video/Climate Cron_2_x264_001.mp4" type='video/mp4;'>
                        </video> -->
                    </div>
                </div>                   
            </div>
        </div> 
    </div>
    
</template>
<script>
import topMenu from "../menus/top-menu.vue"
import orders from '../../pages/user/Orders'
export default {
    data() {
        return {
            isMainBannerShifted: true,                
            isMainBannerHidden: false,   
            drops: [],
            backDrops: [],
        }
    },
    components: {
        "top-menu": topMenu,
        orders
    },
    methods: {
        goMain: function() {
            this.$router.push({ name: 'main'})
        },
        toggleMainBanner: function() {
            this.isMainBannerHidden = !this.isMainBannerHidden;
        }
    },
    props: ['isMainPage'],
    mounted() {
        setTimeout(() => {
            this.isMainBannerShifted = false;            
            let twitterScript = document.createElement('script')
            twitterScript.setAttribute('src', 'https://platform.twitter.com/widgets.js')
            document.head.appendChild(twitterScript)
        }, 0)
    }
}
</script>