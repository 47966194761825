<template>
    <div class="articles managing" :class="classModifier">
        <h2 v-if="header">{{header}}</h2>
        <div v-for="(article, index) in articles" :key="index" class="article__wrapper">
            <article-widget-header :article="article" :showCategory="showCategory" :edit="edit"></article-widget-header>
        </div>
    </div>
</template>
<script>
import ArticleWidgetHeader from './ArticleWidgetHeader.vue';
import axios from 'axios';
export default {
    data() {
        return {
            firstLoad: true,
            articles: null
        }
    },
    watch: {
        page: {
            handler: function(page) {
                this.loadArticles(page);
            }
        }
    },
    methods: {
        loadArticles(currentPage) {
            axios.post(this.$store.state.postUrl, {
                action: 'getArticlesData',
                page: currentPage,
                page_limit: this.limit || null,
                category: this.category || null
            }, this.$store.state.config).then(response => {
                if (typeof response.data == 'object') {
                    this.articles = response.data;
                    if (this.edit) {
                        this.$emit('totalArticles', this.articles.length);
                    }
                }
            }).catch(e => {
                console.error('Some error occured, please, reload the page or contact support')
            })
        }
    },
    props: ['limit', 'header', 'showCategory', 'category', 'edit', 'page', 'classModifier'],
    components: {
        'article-widget-header': ArticleWidgetHeader
    },
    mounted() {
        this.loadArticles(this.page);
    }
}
</script>