<template>
    <div class="login__user">
        <transition name="fade" mode="out-in">
            <div key="1" v-if="displayLogin">
                <h1>Log in</h1>
                <form>
                    <input-field :type="'text'" :name="'email'" :label="'Email'" 
                        @setEmail="setEmail"></input-field>
                    <input-field :type="'password'" :name="'password'" :label="'Password'"
                        @setPassword="setPassword"></input-field>
                    <transition name="fade">
                        <div class="errors" v-if="displayErrors">
                            <div class="error" v-for="(error, index) in registerErrors" :key="index">
                                {{error.message}}
                            </div>
                        </div>
                    </transition>
                    <div class="submit" 
                        :class="{'loginInProgress' : loginInProgress}"
                        @click="userLogin">Log in</div>   
                    <div class="sub_links">
                        <div><router-link :to="{ name: 'restore'}">Forgot your password?</router-link></div>
                        <div>Have no accout? <router-link :to="{ name: 'register'}">Register</router-link></div>
                    </div>
                </form> 
            </div>
            <div key="2" v-if="!displayLogin" 
                class="loading"></div>
        </transition>
    </div>    
</template>
<script>
import input from './Input.vue';
export default {
    data() {
        return {
            email: "",
            password: "",
            loginInProgress: false,
            registerErrors: [],
            displayErrors: false,
            displayLogin: true
        }
    },    
    methods: {
        userLogin: function() {
            this.displayErrors = false;
            this.loginInProgress = true;
            this.$store.dispatch('loginUser', {email: this.email, password: this.password})
        },
        setEmail: function(email) {
            this.email = email;
        },
        setPassword: function(password) {
            this.password = password;
        },
        changeRegisterStatus: function(st) {            
            if (st === 5) {
                setTimeout(() => { 
                    this.displayErrors = true;
                    this.registerErrors = [];
                    this.registerErrors.push({type: 'email', message: "Email or/and password is incorrect"});
                    this.loginInProgress = false;              
                }, 1000)    
            } else if (st == 6) {
                setTimeout(() => { 
                    this.displayErrors = true;
                    this.registerErrors = [];
                    this.registerErrors.push({type: 'activation', message: "Account is not activated."});
                    this.loginInProgress = false;              
                }, 500) 
            } else if (st === 10) {
                setTimeout(() => { 
                    this.displayErrors = true;
                    this.registerErrors = [];
                    this.registerErrors.push({type: 'connection', message: "No connection. Code 401."});
                    this.loginInProgress = false;              
                }, 500)   
            }
        }
    },
    components: {
        'input-field': input
    },
    created() {
        this.$store.watch(
            state => {
                if (state.user.status && this.$router.currentRoute.name !== "personal") {
                    this.$router.push({ name: 'personal'})             
                }
                if (state.userChecked) {
                    this.displayLogin = true;
                }
                this.changeRegisterStatus(state.registerStatus);                
            }
        );
        
        // this.$store.dispatch('currencyData', {currency: 'dollar'});
    }
}
</script>