<template>
    <div class="login__user">
        <h1>{{restoreHeader}}</h1>
        <form>
            <div class="registration__progress registerSucceed" v-if="restoreStatus === 1">
                <p>Check your email</p>
            </div>
            <p v-if="restoreStatus === 9">Ooops, link is broken.</p>
        </form>  
    </div>    
</template>
<script>
export default {
    data() {
        return {
            restoreHeader: '',
            restoreStatus: 0
        }
    },
    methods: {
        changeRestoreStatus: function(st) {                       
            if (st === 1) {          
                this.restoreStatus = st;        
                this.restoreHeader = "We sent you a new password";
            } else if (st === 9) {
                this.restoreStatus = st;  
                this.restoreHeader = "Link is not valid";             
            }       
        },
    },
    mounted() {
        this.$store.commit("SET_REGISTER_STATUS", 0)
        this.$store.watch(
            state => this.changeRestoreStatus(state.registerStatus)
        );
        this.$store.dispatch('resetUser', this.$route.params.link)
    }
}
</script>