<template>
    <div class="login__user">
        <h1>{{restoreHeader}}</h1>
        <transition name="fade" mode="out-in">
            <form key="1" v-if="restoreStatus === 0">
                <input-field :type="'text'" :name="'email'" :label="'Email'"
                    @setEmail="setEmail"></input-field>
                <div class="errors" v-if="displayErrors">
                    <div class="error" v-for="(error, index) in restoreErrors" :key="index">
                        {{error.message}}
                    </div>
                </div>
                <div class="submit" @click="userRestore"
                    :class="{'registerInProgress' : restoreInProgress}">Restore</div>   
                <div class="sub_links">
                    <router-link :to="{ name: 'login'}">Back to login</router-link>
                </div>
            </form>  
            <div key="2" class="registration__progress" v-if="restoreStatus === 1"
                :class="{
                    'registerSucceed' : restoreStatus === 1
                }">
                <p>We have sent an instruction to your email, how to get a new password.</p>
            </div> 
        </transition>
        
    </div>    
</template>
<script>
import input from './Input.vue';
export default {
    data() {
        return {
            restoreInProgress: false,
            email: "",
            restoreErrors: [],
            displayErrors: false,
            restoreStatus: 0,
            restoreHeader: "Restore password"
        }
    },
    methods: {
        setEmail: function(email) {
            this.email = email;
        },
        userRestore: function() {
            this.restoreInProgress = true;
            this.$store.dispatch('startUserRestore', this.email)
        },
        changeRestoreStatus: function(st) {     
            if (st === 1) {
                this.restoreStatus = 1;
                this.restoreHeader = "Check you email"
            } else if (st === 7) {
                setTimeout(() => { 
                    this.displayErrors = true;
                    this.restoreErrors = [];
                    this.restoreErrors.push({type: 'email', message: "User not found."});
                    this.restoreInProgress = false;              
                }, 500)    
            } else if (st === 8) {
                setTimeout(() => { 
                    this.displayErrors = true;
                    this.restoreErrors = [];
                    this.restoreErrors.push({type: 'email', message: "You need to fill email."});
                    this.restoreInProgress = false;              
                }, 500)    
            } else if (st === 10) {
                setTimeout(() => { 
                    this.displayErrors = true;
                    this.restoreErrors = [];
                    this.restoreErrors.push({type: 'connection', message: "No connection. Code 401."});
                    this.restoreInProgress = false;              
                }, 500)   
            }
        }
    },
    components: {
        'input-field': input
    },
    mounted() {
        if (this.$store.state.user.status) {
            this.$router.push({ name: 'personal'})
        }
    },
    created() {
        this.$store.watch(
            state => {
                this.changeRestoreStatus(state.registerStatus);                
            }
        );
    }
    
}
</script>