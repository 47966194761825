<template>
    <div class="currency" v-if="showCurrency">
        <h2>CRON rates</h2>
        <div class="currency-single">
            <div class="currency-label usd">USD</div>
            <span>{{displayCurrency('USD', currency.dollar.course)}}</span>
        </div>
        <div class="currency-single">
            <div class="currency-label euro">EURO</div>            
            <span>{{displayCurrency('EUR', currency.euro.course)}}</span>
        </div>
        <div class="currency-single">
            <div class="currency-label aed">AED</div>            
            <span>{{displayCurrency('AED', currency.aed.course)}}</span>
        </div>
        <div class="currency-single">
            <div class="currency-label cny">CNY</div>
            <span>{{displayCurrency('CNY', currency.cny.course)}}</span>
        </div>
        <h2>mCRON rates</h2>
        <div class="currency-single">
            <div class="currency-label usd">USD</div>            
            <span>$ {{currency.dollar.course / 1000}}</span>
        </div>
        <!-- <h2>Market review</h2>
        <div class="currency-single currency-single-white">
            <div class="currency-label">Totally sold:</div>
            <span><span>{{market ? market['bought_cron'] : '0'}}</span> CRON<br/><span>{{market ? market['bought_mcron'] : '0'}}</span> mCRON</span>
        </div>
        <div class="currency-single currency-single-white">
            <div class="currency-label">Available to buy:</div>
            <span><span>{{market ? market['available_cron'] : '0'}}</span> CRON <br/><span>{{market ? market['available_mcron'] : '0'}}</span> mCRON</span>
        </div>       -->
        <div class="market-date">{{new Date() | moment("dddd, MMMM Do YYYY")}}</div>          
    </div>
</template>
<script>
import displayCurrency from '../helpers/helpers.js'
export default {
    data() {
        return {
            currency: {
                dollar: {},
                euro: {},
                cny: {},
                aed: {}
            },
            showCurrency: false,
            market: null
        }
    },
    methods: {
        ...displayCurrency,
        createCurrency: function() {
            this.$store.state.currencyData.forEach(c => {
                if (c.name === 'dollar') {
                    this.currency.dollar = c;
                } else if (c.name === 'euro') {
                    this.currency.euro = c;
                } else if (c.name === 'aed') {
                    this.currency.aed = c;
                } else if (c.name === 'cny') {
                    this.currency.cny = c;
                }
            })
            this.showCurrency = true;
        },
        createMarket: function() {
            this.market = this.$store.state.marketData;
        }
    },
    created() {
        this.$store.dispatch('currencyData');
        this.$store.dispatch('marketData');
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'SET_CURRENCY_DATA') {
                this.createCurrency();
            }
            if (mutation.type === 'SET_MARKET_DATA') {
                this.createMarket();
            }
        })
    }
}
</script>