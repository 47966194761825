<template>
  <div id="app" @scroll="checkScroll">
    <wrapper></wrapper>
  </div>
</template>
<script>
import wrapper from './template/wrapper.vue';

export default {
  data() {
    return {
      userIp: null,
    }
  },
  components: {
    'wrapper': wrapper
  },
  methods: {    
    checkScroll: function(e) {
      this.$store.dispatch('updateScroll', document.documentElement.scrollTop);
    }
  },
  created() {
    this.$store.dispatch('checkUserAuthorized');
  },
  mounted() {
    window.addEventListener('scroll', this.checkScroll);
  }
}
</script>
<style lang="scss">
  @import "./resources/css/main.scss";
</style>