<template>
    <div class="article internal">
        <h1>{{page}}</h1>
        <p v-if="false">
            The Climate CRON Unit is a new financial instrument enabling the instantaneous transfer of payments between institutional clients, within the <a href="https://climatecron.com/articles/what-is-over-the-counter">OTC market</a>, trading takes place on our platform ClimateCron.com.
        </p>
        <div class="question" v-for="(question, index) in questions" :key="index">
            <full-question :question="question">
            </full-question>
        </div>
        <router-view></router-view>
    </div>
</template>
<script>
import fullQuestion from './FullQuestion.vue';
export default {
    data() {
        return {
            questions: [
                {
                    id: 1,
                    header: "So what exactly is the Climate CRON and what is it used for?",
                    answer: `<p>Climate CRON is a new financial instrument designed to make instantaneous payments. Exchanging value, such as money, between different parties requires a digital currency, so we created the Climate CRON.</p>`
                },
                {
                    id: 2,
                    header: `How does it work?`,
                    answer: `<p> In step 1, a Climate CRON client commits deposits to a designated account and receives an equivalent number of Climate CRON. 
                            <br/>In step 2, these Climate CRONs are used for transactions with other Climate CRON clients (e.g., money movement, payments in securities transactions). 
                            <br/>Finally in step 3, holders of Climate CRONs redeem them for USD.</p>`
                },
                {
                    id: 3,
                    header: `How did you test the Climate CRON?`,
                    answer: `<p>We successfully tested the movement of money between a client account and a Climate CRON account using the new technology.</p>`
                },
                {
                    id: 5,
                    header: `What impact will Climate CRON have on other businesses?`,
                    answer: `<p>It is still too early to assess the ultimate impact of Climate CRON on different businesses.
                            We continue to work with our clients around the world to explore different use cases for this type of technology.</p>`
                },
                {
                    id: 6,
                    header: `Will it be used for other currencies besides the U.S. Dollar?`,
                    answer: `<p>Over time, Climate CRON will be extended to other major currencies. The product and technology capabilities are currency agnostic.</p>`
                }
            ],
            page: "F.A.Q."
        }
    },
    components: {
        'fullQuestion': fullQuestion
    }
}
</script>