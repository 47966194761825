<template>
    <div class="article internal">
        <h1>{{page}}</h1>
        <p v-if="false">Climate CRON is a new financial instrument within the <a href="https://climatecron.com/articles/what-is-over-the-counter">OTC market</a>, trading takes place on our platform ClimateCron.com, that was created by Climate Global Control Trading LLC. Our solution will allow participants of global market of water resources to leverage their business with a full range of climate services.
        <p v-if="false">While we have a lot to achieve the effective operation of CLIMATE CRON Platform will allow to implement a successful monetization of global market of water resources and promote the globalization.</p>
        <p v-if="false">There will be no need for sellers to incur the costs related to 3rd party registries to record sales and retirement of assets. An innovative aspect is that it automatically prevents double-spend of natively loaded assets, resulting in a fraud-resistant solution.</p>
        <p style="text-decoration: underline;">The usage of Climate CRON will allow further more advantages:</p>
        <ul class="regular">
            <li>Effectively monetize and globalize the most important market of water resources.</li>
            <li>Create a new world precedent for solving of global problems.</li>
            <li>Participants of global market of water resources will have an alternative reserve currency which will not be exposed to economic and political influence of third countries.</li>
            <li>Save budgetary and extra-budgetary funds and release financial resources.</li>
            <li>Create a Climate Stock Exchange for trading on climate services with a single information space.</li>
        </ul>
        <h2>About "Climate Global Control Trading LLC"</h2>
        <p>"Climate Global Control Trading L.L.C." offers its unique service with innovative technology foroutperforming the standard understanding the impact on the climate for decades tocome, which allows to control the weather, temperature, precipitation, increasing thegroundwater level.<br/>All this can be done at predetermined area for pre-assigned periodof time.
        <p>"Climate Global Control Trading L.L.C." is a leading suppliers of climate services for theglobal market of water resources. Team of experienced professional have been involved since three decades fromdifferent parts of the world. Our team consist of experts from the USSR from differentcountries and 406 high-educated, uniquely experienced professionals. Our networkaround the world can make us reach nearly 3000 specialists to get involved with ourteam.</p>
        <p>The integrated technology solution for managed weather change will allow to take into account the climatic characteristics of defined area (territory) and to increase precipitation, regulate groundwater levels, eliminate the causes of dust storms, and also levelling of seasonal temperature contrasts to a comfortable level in a certain area of the region.
        <p>The result derived will allow in the certain area or in the defined territory of the Customer is settled the necessary weather regime for a certain period of time.
        <p>The results of the application of technology for weather management will be visible within 2-3 months. Integrated use of technology for weather management within 2-3 years will solve the main country's needs in water resources.</p>
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page: "Climate CRON"
        }
    }
}
</script>