<template>
    <form>
        <h3>My personal settings</h3>
        <input-field :type="'text'" :name="'name'" 
            :inputError="formErrors.name" 
            :inputValue="name"
            :label="'Your name'"
            @setName="setName">
        </input-field>
        <input-field :type="'password'" :name="'password'" 
            :inputError="formErrors.password" 
            :inputValue="password"
            :label="'New password'" 
            @setPassword="setPassword">
        </input-field>
        <input-field :type="'password'" :name="'repeat-password'" 
            :inputError="formErrors.password" 
            :inputValue="passwordRepeat"
            :label="'Repeat new password'" 
            @setPasswordRepeat="setPasswordRepeat">
        </input-field>
        <input-field :type="'text'" :name="'phone'" 
            :label="'Contact phone'"
            :inputValue="phone"
            @setPhone="setPhone">
        </input-field>
        <div class="sub_dropdown" v-on-click-outside="hideCountries">
            <input type="text" name="country" ref="country"
                :class="{'error': formErrors.country}"
                :value="country"
                @change="showCountries" 
                @keyup="showCountries"
                @focusin="showCountries"
                @focusout="unshiftSpan"
            >
            <span :class="{'shifted': isShifted || country.trim() !== ''}">Country</span>
            <div class="dropdown_list" v-if="isCountryListVisible">
                <div class="dropdown_item" v-for="(country, index) in filteredCountries" 
                    :key="index"
                    @click="fillCountry">
                    <span v-html="country"></span>
                </div>
            </div>
        </div>
        <transition name="fade">
            <div class="success" v-if="userSaved">
                Changes are saved
            </div>
        </transition>                    
        <div class="errors" v-if="displayErrors">
            <div class="error" v-for="(error, index) in registerErrors" :key="index">
                {{error.message}}
            </div>
        </div>
        <div class="submit" @click="saveUser"
            :class="{'registerInProgress' : registerInProgress}"
        >Save</div>  
    </form>
</template>

<script>
const countries = require('country-list');
import { mapGetters } from 'vuex'
import input from './Input.vue';
export default {
    data() {
        return {
            name: this.$store.state.user.name,
            password: "",
            passwordRepeat: "",
            phone: this.$store.state.user.phone,
            country: this.$store.state.user.country,
            formErrors: {
                country: false, 
                passwordRepeat: false, 
                password: false,
                name: false
            },
            displayErrors: false,
            filteredCountries: [],
            isCountryListVisible: false,
            registerInProgress: false,
            ignorePassword: false,
            isShifted: false,
            userSaved: false,
        }
    },
    components: {
        'input-field': input
    },
    computed: {
        ...mapGetters(['registerStatus']),
        getRegisterStatus() {      
            return this.registerStatus
        }        
    },
    methods: {
        setPassword: function(pwd) {
            this.password = pwd;
            this.checkCorrectPasswords();
        },
        setPasswordRepeat: function(pwd) {
            this.passwordRepeat = pwd;
            this.checkCorrectPasswords();
        },
        showCountries: function(e) {
            this.country = e.target.value;
            this.shiftSpan(e);
            let tempCountries = countries.getNames().filter(country => country.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1);
            if (tempCountries.length === 0) {
                tempCountries.push("<span class='no-matches'>No countries found</span>")
            }
            this.filteredCountries = tempCountries;
            this.formErrors.country = false;
            if (e.target.value.toString().length > 0) {
                this.isCountryListVisible = true;
            } else {
                this.isCountryListVisible = false;
            }
        },
        hideCountries: function() {
            if (this.isCountryListVisible) {
                this.isCountryListVisible = false;
                this.checkCorrectCountry();
            }
        },
        fillCountry: function(e) {
            if (!e.target.classList.contains('no-matches')) {
                this.$refs.country.value = e.target.innerText;
                this.country = e.target.innerText;
            }            
            this.hideCountries();
        },
        setName: function(name) {
            this.name = name;
            this.checkName();
        },
        setPhone: function(phone) {
            this.phone = phone;
        },
        shiftSpan: function() {
            this.isShifted = true;
        },
        unshiftSpan: function(e) {
            if (e.target.value.trim() === "") {
                this.isShifted = false;
            }     
        },
        checkCorrectCountry: function() {
            let foundCoutry = countries.getNames().find(country => {
                return country.toLowerCase() === this.$refs.country.value.toLowerCase()
            })
            if (foundCoutry) {
                this.formErrors.country = false;
            } else {
                this.formErrors.country = true;
            }
        },
        checkCorrectPasswords: function() {
            this.ignorePassword = false;
            this.formErrors.password = false;
            this.formErrors.passwordRepeat = false;
            if (this.password.trim() === "" && this.passwordRepeat.trim() === "") {
                this.ignorePassword = true;
            }
            if (!this.ignorePassword) {
                if (this.password.trim() === "") {
                    this.formErrors.password = true;
                } 
                if (this.passwordRepeat.trim() === "") {
                    this.formErrors.passwordRepeat = true;
                }
                if (this.password !== this.passwordRepeat) {
                    this.formErrors.password = true;
                    this.formErrors.passwordRepeat = true;
                }
            }
        },     
        checkName: function() {
            if (this.name.trim() !== "") {
                this.formErrors.name = false;
            } else {
                this.formErrors.name = true;
            }
        },
        saveUser: function() {
            if (this.registerInProgress) {
                return false;
            }
            this.displayErrors = false;
            this.checkCorrectPasswords();
            this.checkCorrectCountry();
            this.checkName();
            let noErrors = true;
            for (const key of Object.keys(this.formErrors)) {
                const error = this.formErrors[key];          
                if (error === true) {
                    noErrors = false;
                }      
            }
            if (noErrors) {
                this.$store.state.newUser.name = this.name;
                this.$store.state.newUser.password = this.ignorePassword ? false : this.password;
                this.$store.state.newUser.phone = this.phone;
                this.$store.state.newUser.country = this.country;
                this.registerInProgress = true;
                this.$store.dispatch('startUserSaving');
            }
        },
        updateError: function(type) {
            for (let i = this.registerErrors.length - 1; i >= 0; i--) {
                if (this.registerErrors[i].type === type) {
                    this.registerErrors.splice(i, 1);
                }
            }
            if (this.registerErrors.length === 0) {
                this.displayErrors = false;
            } 
        },
        changeRegisterStatus(status) {               
            if (status === 1) {
                this.registerInProgress = false;
                this.userSaved = true;
                setTimeout(() => {
                    this.userSaved = false
                }, 2000)
            } else if (status === 11) {
                this.displayErrors = true;
                this.registerErrors = [];
                this.registerErrors.push({type: 'name', message: "No connection. Code 401."});
                this.registerInProgress = false;   
            }
            return status
        }
    },
    watch: {
        getRegisterStatus(newVal) {
            this.changeRegisterStatus(newVal)
        }
    },
    mounted() {
        this.$store.watch(
            state => {
                if (state.user.status) {
                    this.displayCabinet = true;
                }
            }
        );
    }
}
</script>