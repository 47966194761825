<template>
    <div class="header__wrapper-menu" :class="{'opened': isMenuVisible}">
        <div class="header__menu header__menu-desktop"
            :style="{'left': !isMenuVisible ? 'calc( -100% - 33px)' : '-10px'}">
            <router-link :to="{ name: 'main'}" @click.native="toggleMenu">Main</router-link>
            <router-link :to="{ name: 'about'}" @click.native="toggleMenu">About</router-link>
            <a href="https://climatecron.com/articles/white-paper-of-climate-cron">White Paper</a>
            <a href="https://climatecron.com/sell">Sell Climate Cron</a>
            <!-- <router-link :to="{ name: 'partners'}" @click.native="toggleMenu">Partners</router-link> -->
            <router-link :to="{ name: 'contacts'}" @click.native="toggleMenu">Contacts</router-link>
            <router-link :to="{ name: 'personal'}" @click.native="toggleMenu" class="account" v-if="$store.state.user.status">Account</router-link>
            <router-link :to="{ name: 'login'}" @click.native.prevent="logoutUser" v-if="$store.state.user.status">Log out</router-link>
            <router-link :to="{ name: 'login'}" @click.native="toggleMenu" v-if="!$store.state.user.status">Login</router-link>        
            <router-link :to="{ name: 'register'}" @click.native="toggleMenu" class="register" v-if="!$store.state.user.status">Register</router-link>
            <div class="mobile-logo"></div>
        </div>        
        <div class="burger-menu" :class="{'opened': isMenuVisible}" v-touch:tap="toggleMenu">
            <div class="burger__line"></div>
            <div class="burger__line"></div>
            <div class="burger__line"></div>
        </div>
        <div class="header__contacts"><a href="mailto:info@climatecron.com">info@climatecron.com</a></div>
    </div>
    
</template>
<script>
export default {
    data: function() {
        return {
            isMenuVisible: false
        }
    },
    methods: {
        toggleMenu: function() {
            this.isMenuVisible = !this.isMenuVisible;
            this.$emit('toggleMainBanner')
        },
        logoutUser: function() {
            this.toggleMenu();
            this.$store.dispatch('logoutUser');
        }
    }
}
</script>