<template>
    <div>
        <h3>{{title}}</h3>
        <div class="orders-all">
            <div class="single-order single-order-header">
                <div>ID</div>
                <div>Amount</div>
                <div>Price</div>
                <div>Open date</div>
                <div></div>
            </div>
            <single-order 
                :order="order" 
                v-for="order in orderData" 
                :key="order.id"
                :mine="!guest ? $store.state.user.id === order.user_id : true" 
                :guest="guest" 
                @updateBalance="updateBalance"
                @loadOrders="loadOrders">
            </single-order>
        </div>
        <div class="stock-pagination" v-if="!guest">
            <span v-if="page > 1" @click="getOrder(page - 1)" >Prev</span>
            <span 
                v-if="orderData.length >= ordersPerPage || page > 1" 
                class="deactive" >{{page}}</span>
            <span 
                v-if="orderData.length >= ordersPerPage"
                @click="getOrder(page + 1)">Next</span>
        </div>
    </div>
</template>
<script>
import OrderSingle from './OrderSingle.vue';
// import axios from 'axios';
export default {
    data() {
        return {
            page: 1,
            ordersPerPage: 30,
            ordersOpen: 0
        }
    },
    computed: {
        orderData() {
            return  this.user_id !== null ? this.$store.state.myOrderData : this.$store.state.buyOrderData;
        }
    },
    props: ['type', 'user_id', 'title', 'guest'],
    components: {'single-order': OrderSingle},
    methods: {
        loadOrders() {
            this.$store.dispatch('loadBuyOrders', {
                type: this.type,
                page: this.page,
                guest: this.guest,
                user_id: this.user_id || null,
                page_limit: this.guest ? 4 : this.ordersPerPage
            })
        },
        getOrder(page) {
            this.page = page;
            this.loadOrders()
        },
        updateBalance() {
            this.$store.dispatch('updateBalance');
        }
    },
    mounted() {
        this.loadOrders();
    }
}
</script>