<template>
    <div class="footer">
        <div class="footer__wrapper">
            <bottom-menu></bottom-menu>            
        </div>        
        <div class="copyright">
            <p>© 2017 - {{ new Date().getFullYear() }} Climatecron.com. All rights reserved</p>
        </div>
    </div>
</template>
<script>
import bottomMenu from '../menus/bottom-menu.vue';
export default {
    data() {
        return {
            
        }
    },
    components: {
        'bottom-menu': bottomMenu
    }
}
</script>