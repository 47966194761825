<template>
    <div class="login__user">
        <h1>{{activationHeader}}</h1>
        <form>
            <p v-if="activationStatus === 1">Now you can log in, using your email and password.</p>
            <p v-if="activationStatus === 4">Ooops, account was already activated or link is broken.</p>
            <div class="sub_links full-button">
                <router-link :to="{ name: 'login'}">Login</router-link>
            </div>
        </form>  
    </div>    
</template>
<script>
export default {
    data() {
        return {
            activationHeader: '',
            activationStatus: 0
        }
    },
    methods: {
        changeActivationStatus: function(st) {      
            this.activationStatus = st;         
            if (st === 1) {                
                this.activationHeader = "Your account has been activated";
            } else if (st === 4) {
                this.activationHeader = "Link is not valid";             
            }          
        },
    },
    mounted() {
        this.$store.commit("SET_REGISTER_STATUS", 0)
        this.$store.watch(
            state => this.changeActivationStatus(state.registerStatus)
        );
        this.$store.dispatch('activateUser', this.$route.params.link)
    }
}
</script>