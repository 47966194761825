<template>
    <div class="stock">
        <div class="stock-row stock-row-subheader">
            <div class="stock-cell">Date</div>
            <div class="stock-cell">Value</div>
        </div>
        <div v-if="!displayInput" class="stock-row">
            <div class="stock-cell">{{stock_date | moment("YYYY-MM-DD")}}</div>
            <div class="stock-cell">{{stock_value}}</div>
        </div>
        <div v-if="displayInput" class="stock-row narrow">          
            <datepicker @selected="formatDate" :value="stock_date" format="yyyy-MM-dd" placeholder="Select Date"></datepicker>
            <input v-model="stock_value">
        </div>
        <div class="stock__control">
            <span v-if="!displayInput" @click="showInput" class="stock__edit">edit</span>
            <span v-if="displayInput" @click="saveInput" class="stock__save">save</span>
            <span @click="deleteInput" class="stock__delete">delete</span>
        </div>
    </div>
</template>
<script>
import Datepicker from 'vuejs-datepicker';
export default {
    data() {
        return {
            displayInput: false,
            stock_value: this.stock.stock_value,
            stock_date: this.stock.date
        }
    },
    components: {Datepicker},
    props: ['stock', 'page'],
    filters: {
        formatDate(value) {
            return value;
        }
    },
    methods: {        
        showInput() {
            this.displayInput = true;
        },
        openPicker() {
            this.$refs.programaticOpen.showCalendar();
        },        
        deleteInput() {
            let confirmation = confirm("Are you sure to delete?");
            if (confirmation) {
                this.$store.dispatch('deleteStockData', {action: 'deleteStockForecastData', page: this.page, stock_id: this.stock.id});
            }            
        },
        formatDate(e) {
            this.stock_date = this.$options.filters.moment(e, "YYYY-MM-DD");
        },
        saveInput() {
            this.displayInput = false;
            this.$store.dispatch('saveStockForecastData', {
                page: this.page, 
                action: 'setStockForecastData',
                stock_id: this.stock.id, 
                stock_value: this.stock_value, 
                stock_date: this.stock_date
            })
        }
    }
}
</script>