<template>
    <div class="article internal">
        <h1><span>404</span><br/><br/>Page does not exist</h1>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page: "Climate CRON"
        }
    }
}
</script>