<template>
    <div>
        <div>{{apply.name}}<br/><a :href="`mailto:${apply.email}`">{{apply.email}}</a><br/><span v-if="apply.country !== ''">({{apply.country}})</span></div>
        <div>{{apply.coins}} {{apply.coins_type == 2 ? 'mCRON' : 'CRON'}}</div>
        <div>{{apply.date | moment("DD/MM/YYYY")}}</div>
        <div>
            <div class="submit apply-accept" @click="acceptApply">Accept</div>
            <div class="submit apply-decline blue" @click="declineApply">Decline</div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    props: ['apply', 'order'],
    methods: {
        declineApply() {
            let confirmation = confirm("Are you sure to decline this request?");
            if (confirmation === true) {
                axios.post(this.$store.state.postUrl, {
                    action: 'declineAppliedOrder',
                    apply_id: this.apply.id,
                    order_id: this.order.id,
                    user_id: this.apply.user_id
                }, this.$store.state.config).then(response => {
                    if (response.data.result === true) {
                        this.$emit('getAppliedOrders');
                    }
                }).catch(e => {
                    console.error(e + ': Some error occured, please, reload the page or contact support')
                })
            }
        },
        acceptApply() {
            let confirmation = confirm("Are you sure to accept this request?\nDid you get payment for those CRONs?\nIf you confirm, CRONs will be transferred from your account to Buyer's account!");
            if (confirmation === true) {
                axios.post(this.$store.state.postUrl, {
                    action: 'acceptAppliedOrder',
                    apply_id: this.apply.id,
                    order_id: this.order.id,
                    user_id: this.apply.user_id,
                    coins: this.apply.coins,
                    coins_type: this.apply.coins_type,
                    price: this.order.price
                }, this.$store.state.config).then(response => {
                    if (response.data.result === true) {
                        this.$emit('getAppliedOrders');
                    } else {
                        alert('There are no more enough units in your order to accept request. You should decline the request.');
                    }
                }).catch(e => {
                    console.error(e + ': Some error occured, please, reload the page or contact support')
                })
            }
            
        }
    }
}
</script>