<template>
    <div :class="{'expanded': expanded}">
        <h3 @click="toggleExpand">{{question.header}}</h3>
        <div v-html="question.answer"></div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page: "Full",
            expanded: false
        }
    },
    props: ['question'],       
    methods: {
        toggleExpand: function() {
            this.expanded = !this.expanded
        }
    }
}
</script>