import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../pages/main/Main.vue'
import About from '../pages/main/About.vue'
import NotFound from '../pages/main/404.vue'
import Terms from '../pages/main/Terms.vue'
import FAQ from '../pages/main/FAQ.vue'
// import Agreement from '../pages/main/Agreement.vue'
// import Whitepapers from '../pages/main/Whitepapers.vue'
import Concept from '../pages/main/Concept.vue'
import Contacts from '../pages/main/Contacts.vue'
import Partners from '../pages/main/Partners.vue'
import Order from '../pages/main/Presale.vue'
import Sell from '../pages/main/Sell.vue'
import Currency from '../pages/main/Currency.vue'
import Account from '../pages/user/Account.vue'
import Register from '../pages/user/Register.vue'
import Restore from '../pages/user/Restore.vue'
import Reset from '../pages/user/Reset.vue'
import Login from '../pages/user/Login.vue'
import Activate from '../pages/user/Activate.vue'
import Personal from '../pages/user/Personal.vue'
import Article from '../pages/main/Article.vue'
import ArticleEdit from '../pages/main/ArticleEdit.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: Main,
    meta: {
      title: 'Climate CRON - new financial instrument, provided by fresh water'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      title: 'About Climate Cron'
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQ,
    meta: {
      title: 'Frequently asked questions about Climate CRON'
    }
  },
  {
    path: '/partners',
    name: 'partners',
    component: Partners,
    meta: {
      title: 'Official partners'
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms,
    meta: {
      title: 'Terms of Use'
    }
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: Contacts,
    meta: {
      title: 'Contacts'
    }
  },
  {
    path: '/order',
    name: 'presale',
    component: Order,
    meta: {
      title: 'Purchase Climate Cron'
    }
  },
  {
    path: '/sell',
    name: 'sale',
    component: Sell,
    meta: {
      title: 'Sell Climate Cron'
    }
  },
  // {
  //   path: '/agreement',
  //   name: 'agreement',
  //   component: Agreement,
  //   meta: {
  //     title: 'Purchase Agreement'
  //   }
  // },
  {
    path: '/concept',
    name: 'concept',
    component: Concept,
    meta: {
      title: 'Concept of Monetization and Marketing of Global Market of Water Resources'
    }
  },
  // {
  //   path: '/whitepapers',
  //   name: 'whitepapers',
  //   component: Whitepapers,
  //   meta: {
  //     title: 'Whitepapers'
  //   }
  // },
  {
    path: '/currency',
    name: 'currency',
    component: Currency,
    meta: {
      title: 'Currency preview'
    }
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: {
      title: 'My account'
    },
    children: [
      {
        name: 'register',
        path: 'register',
        component: Register,
        meta: {
          title: 'Registration'
        },
      },
      {
        name: 'restore',
        path: 'restore',
        component: Restore,
        meta: {
          title: 'Restore password'
        },
      },
      {
        name: 'login',
        path: 'login',
        component: Login,
        meta: {
          title: 'Login'
        },
      },
      {
        name: 'personal',
        path: 'personal',
        component: Personal,
        meta: {
          title: 'My account'
        },
      },
      {
        name: 'activate',
        path: 'activate/:link',
        component: Activate,
        meta: {
          title: 'Account activation'
        },
      },
      {
        name: 'reset',
        path: 'reset/:link',
        component: Reset,
        meta: {
          title: 'Reset password'
        },
      },
      {
        name: 'add',        
        path: 'article',   
        component: ArticleEdit,
        children: [
          {
            path: ':category',
            name: 'category-add',
            component: ArticleEdit,
            children: [
              {
                path: ':url',
                name: 'article-add',
                component: ArticleEdit
              }
            ] 
          }
        ] 
      },
      {
        name: 'edit',        
        path: 'edit',   
        component: ArticleEdit,
        children: [
          {
            path: ':category',
            name: 'category-edit',
            component: ArticleEdit,
            children: [
              {
                path: ':url',
                name: 'article-edit',
                component: ArticleEdit,
                meta: {
                  title: ''
                },
              }
            ]
          }
        ] 
      },
    ]
  },  
  {
    path: '/:category',
    name: 'category',
    component: Article,
    children: [
      {
        path: ':url',
        name: 'article',
        component: Article,
        meta: {
          title: ''
        },
      }
    ]
  },
  {
    name: '404',
    path: '/404',
    component: NotFound,
    meta: {
      title: 'Page not found'
    }
  },
  { path: '*', component: NotFound }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  base: process.env.BASE_URL
})

export default router
