<template>
    <div class="center">
        <div class="article internal fake">
            <div class="currency managing">
                <div class="currency__course">
                    <div class="currency-single"><div class="currency-label">Bought</div> <input v-model="bought"></div>
                    <div class="currency-single"><div class="currency-label">Available</div> <input v-model="available"></div>
                </div>
                <transition name="fade">
                    <div class="success" v-if="marketSaved">
                        Changes are saved
                    </div>
                </transition>  
                <div class="currency-save" @click="saveMarket">Save</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            bought: 0,
            available: 0,
            marketSaved: false,
            firstLoad: true
        }
    },
    methods: {
        loadMarket() {
            this.bought = this.$store.state.marketData['bought_cron'];
            this.available = this.$store.state.marketData['available_cron'];
            if (!this.firstLoad) {
                this.marketSaved = true;
            }
            this.firstLoad = false;
            setTimeout(() => {
                this.marketSaved = false
            }, 2000)
        },
        saveMarket() {
            this.$store.dispatch('saveMarketData', [this.bought, this.available]);
        }
    },
    mounted() {
        this.$store.dispatch('marketData');
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'SET_MARKET_DATA') {
                this.loadMarket();
            }
        })
    }
}
</script>