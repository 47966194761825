<template>
    <div class="article internal">
        <template v-if="article != null">
            <h1 class="aligned-left">{{pageHeader}}</h1>
            <div class="articles__buttons articles__buttons-top"> 
                <div class="btn-cancel cancel" @click="cancelArticle">Back</div>
            </div>
            <div class="article__date">
                <div class="article__edit_line article__edit_line-wide">
                    <span>Header</span>
                    <input type="text" v-model="article.name">
                </div>
                <div class="article__edit_line">
                    <span>Date</span>
                    <input type="text" name="date" v-model="article.date_edit">
                </div>
                <div class="article__edit_line">
                    <span>Category</span>
                    <select v-model="article.category.id">
                        <option name="category" value="1">News</option>
                        <option name="category" value="2">Analytics</option>
                        <option name="category" value="3">Articles</option>
                    </select>
                </div>
                <div class="article__edit_line">
                    <span>Url</span>
                    <input type="text" v-model="article.url">
                </div>
                <div class="article__edit_line">
                    <span>Published</span>
                    <select v-model="article.status">
                        <option name="category" value="1">Yes</option>
                        <option name="category" value="2">No</option>
                    </select>
                </div>
            </div>
            <div class="text_full">
                <textarea class="article__edit_text" v-model="article.text_full"/>
            </div>
            <!-- <div class="text_full">
                <editor
                :init="{ height: 500, menubar: false, plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                    ], toolbar: 'undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | code image'}" 
                    v-model="article.text_full"
                />
            </div> -->
            <transition name="fade">
                <div class="success article__edition" v-if="articleSaved">
                    Changes are saved
                </div>
            </transition> 
            <div class="articles__buttons"> 
                <div v-if="!isCreation" class="btn-delete submit" @click="deleteArticle">Delete</div> 
                <div class="btn-cancel cancel" @click="cancelArticle">Cancel</div>  
                <div v-if="!isCreation" class="btn-save submit" @click="saveArticle">Save</div> 
                <div v-if="isCreation" class="btn-save submit" @click="createArticle">Create</div> 
            </div>
            <router-link style="display: block; margin-top: 20px; text-align: center;" target="_blank" v-if="!isCreation" :to="{ name: 'article', params: {url: article.url, category: article.category.url}}">Preview</router-link>
        </template>
    </div>
</template>
<script>
import axios from 'axios';
// import Editor from '@tinymce/tinymce-vue'
export default {
    data() {
        return {
            editorConfig: {                
            },
            article: null,
            articleSaved: false,
            pageHeader: '',
            isCreation: false
        }
    },
    components: {
        // 'editor': Editor
    },
    methods: {
        saveArticle() {
            axios.post(this.$store.state.postUrl, {
                action: 'saveArticleData',
                article: this.article
            }, this.$store.state.config).then(() => {
                this.articleSaved = true;
                setTimeout(() => {
                    this.articleSaved = false
                }, 2000)
            }).catch(() => {
                console.error('Some error occured, please, reload the page or contact support')
            })
        },
        cancelArticle() {
            this.$router.push({ name: 'personal'})
        },
        deleteArticle() {
            let confirmation = confirm("Are you sure to delete?");
            if (confirmation) {
                axios.post(this.$store.state.postUrl, {
                    action: 'deleteArticle',
                    article: this.article
                }, this.$store.state.config).then(() => {
                    this.$router.push({ name: 'personal'})
                }).catch(() => {
                    console.error('Some error occured, please, reload the page or contact support')
                })
            }
        },
        createArticle() {
            axios.post(this.$store.state.postUrl, {
                action: 'createArticle',
                article: this.article
            }, this.$store.state.config).then(response => {
                this.articleSaved = true;
                setTimeout(() => {
                    this.articleSaved = false
                }, 2000)
                this.article = response.data;
                this.article.url = this.article.url.replace(/<\/?[^>]+(>|$)/g, "");
                this.pageHeader = 'Editing';
                this.isCreation = false;
            }).catch(() => {
                console.error('Some error occured, please, reload the page or contact support')
            })
        },
        getArticleData() {
            axios.post(this.$store.state.postUrl, {
                action: 'getArticleData',
                category: this.$route.params.category,
                article: this.$route.params.url
            }, this.$store.state.config).then(response => {
                this.article = response.data[0];
                this.article.url = this.article.url.replace(/<\/?[^>]+(>|$)/g, "");
                this.pageHeader = 'Editing';
                this.isCreation = false;
            }).catch(() => {
                console.error('Some error occured, please, reload the page or contact support')
            })
        }
    },
    mounted() {
        if (this.$store.state.user.rights < 125 || !this.$store.state.user.rights) {
            this.$router.push({ name: 'personal'})
        } else {
            if (this.$route.params.category === 'add' && this.$route.params.url === 'new') {
                this.article = {
                    name: '',
                    date_edit: this.$options.filters.moment(new Date(), "YYYY-MM-DD hh:mm:ss"),
                    text_full: '',
                    category: {
                        id: 1,
                        name: 'News',
                        url: 'news'
                    },
                    url: '',
                    status: 1
                }
                this.pageHeader = 'New article';
                this.isCreation = true;
            } else {
                this.getArticleData();
            }
        }
        
    }
}
</script>