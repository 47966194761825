<template>
    <div class="wrapper">
        <site-header
            :isMainPage="isMainPage">
        </site-header>
        <site-center
            :isMainPage="isMainPage">
        </site-center>
        <site-footer
            :isMainPage="isMainPage">
        </site-footer>        
    </div>
</template>
<script>
import siteHeader from "./header/site-header.vue";
import siteCenter from "./center/site-center.vue";
import siteFooter from "./footer/site-footer.vue";

export default {
    data() {
        return {
            isMainPage: false,
        }
    },
    methods: {
        changeTitle(route) {
            this.isMainPage = route.name === "main";
            document.title = route.meta.title || 'Climate CRON'
        }
    },
    components: {
       "site-header": siteHeader,
       "site-center": siteCenter,
       "site-footer": siteFooter
    },
    watch: {
        $route: {
            handler: function(route) {
                this.changeTitle(route);
            }
        }
    },
    created() {
        this.isMainPage = this.$route.name === "main";
        this.changeTitle(this.$route);      
    }
}
</script>