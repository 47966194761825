<template>
    <div>
        <div class="stock stock-main">
            <div class="stock-row stock-row-header">
                <div class="stock-cell">Date</div>
                <div class="stock-cell">Open</div>
                <div class="stock-cell">Close</div>
                <div class="stock-cell">High</div>
                <div class="stock-cell">Low</div>
            </div>
            <div class="stock__control">
                <span class="stock__add" @click="addStock">Add new</span>
                <span class="stock__delete"></span>
            </div>
        </div>
        <div v-for="stock in stockData" :key="stock.id">
            <single-stock :stock="stock" :page="page"></single-stock>
        </div>
        <div class="stock-pagination">
            <span v-if="page > 1" @click="getStock(page - 1)" >Prev</span>
            <span class="deactive" v-if="stockData.length >= stockPerPage || page > 1">{{page}}</span>
            <span v-if="stockData.length >= stockPerPage" @click="getStock(page + 1)">Next</span>
        </div>
    </div>
</template>
<script>
import SingleStock from './StockSingle.vue';

export default {
    data() {
        return {
            page: 1,
            stockPerPage: 30,
            stockData: []
        }
    },
    components: {'single-stock': SingleStock},
    methods: {
        loadStock() {
            this.stockData = this.$store.state.stockData;
        },
        addStock() {
            this.$store.dispatch('addStockData', {page: 1, action: 'addStockData'});
        },
        getStock(page) {
            this.page = page;
            this.$store.dispatch('stockData', {page, action: 'getStockData'});
        }
    },
    mounted() {
        this.$store.dispatch('stockData', {page: this.page, action: 'getStockData'});
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'SET_STOCK_DATA') {
                this.loadStock();
            }
        })
    }
}
</script>