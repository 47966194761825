
<template>
    <div class="article internal">   
        <div class="platform">
            <div class="chart__wrapper">
                <h1>CRON chart</h1>
                <div id="chartdiv" ref="chartdiv"></div>
            </div>
            <currency-exchange></currency-exchange>
        </div>
        <div class="platform">
            <div class="chart__wrapper narrow">
                <h2>Analytic forecast</h2>
                <div id="anchartdiv" ref="anchartdiv"></div>
            </div>
            <article-widget :limit="4" :header="`News`" :showCategory="false" :category="1" :page="1"></article-widget>        
            <article-widget :limit="4" :header="`Experts analytics`" :showCategory="false" :category="2" :page="1"></article-widget>   
        </div>
        <router-view></router-view>
    </div>
</template>
<script>
/* eslint-disable no-unused-vars */
// import * as am4core from "@amcharts/amcharts4/core";
// import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// import { now } from '@amcharts/amcharts4/.internal/core/utils/Time';
import ArticleWidget from './ArticleWidget.vue';
import CurrencyExchange from './CurrencyExchange.vue';
export default {
    data() {
        return {
            page: "Currency"
        }
    },
    components: {
        'article-widget': ArticleWidget,
        'currency-exchange': CurrencyExchange
    },
    methods: {
        // createChart: function() {
        //     if (this.$router.currentRoute.name !== 'currency') {
        //         return false;
        //     }
        //     am4core.useTheme(am4themes_animated);
        //     let chart = am4core.create("chartdiv", am4charts.XYChart);
        //     chart.paddingRight = 20;
        //     chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
        //     chart.zoomOutButton.align = "right";
        //     chart.zoomOutButton.valign = "bottom";
        //     chart.background.visible = false;
        //     let dateAxis = chart.xAxes.push(new am4charts.DateAxis());        
        //     dateAxis.renderer.grid.template.location = 1;
        //     dateAxis.renderer.fontSize = "14px";
        //     dateAxis.dateFormats.setKey("day", "yyyy-MM-dd");
        //     dateAxis.minZoomCount = 50;
        //     dateAxis.groupData = true;
        //     dateAxis.skipEmptyPeriods = true; 
        //     dateAxis.startLocation = 0.5;
        //     dateAxis.endLocation = 0.5;

        //     let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        //     valueAxis.tooltip.disabled = false;
        //     valueAxis.height = am4core.percent(100);
        //     valueAxis.renderer.fontSize = "14px";
        //     valueAxis.min = valueAxis.minZoomed;
        //     valueAxis.max = valueAxis.maxZoomed;
        //     let series = chart.series.push(new am4charts.CandlestickSeries());
        //     series.dataFields.dateX = "date";
        //     series.dataFields.valueY = "stock_close";
        //     series.dataFields.openValueY = "stock_open";
        //     series.dataFields.lowValueY = "stock_low";
        //     series.dataFields.highValueY = "stock_high";
        //     series.tooltipText = "[font-size:13px]Date: {dateX}\nOpen: $ {openValueY.value}\nClose: $ {valueY.value}[/]";
        //     series.fontSize = "12px";

        //     // beauty
        //     let axisTooltip = dateAxis.tooltip;
        //     axisTooltip.background.fill = am4core.color("#01518C");
        //     axisTooltip.background.strokeWidth = 0;
        //     axisTooltip.background.cornerRadius = 2;
        //     axisTooltip.background.pointerLength = 5;
        //     axisTooltip.dy = 5;
        //     axisTooltip.fontSize = "13px"

        //     let dropShadow = new am4core.DropShadowFilter();
        //     dropShadow.dy = 1;
        //     dropShadow.dx = 1;
        //     dropShadow.opacity = 0.5;
        //     axisTooltip.filters.push(dropShadow);

        //     // beauty
        //     axisTooltip = valueAxis.tooltip;
        //     axisTooltip.background.fill = am4core.color("#01518C");
        //     axisTooltip.background.strokeWidth = 0;
        //     axisTooltip.background.cornerRadius = 2;
        //     axisTooltip.background.pointerLength = 5;
        //     axisTooltip.fontSize = "14px"
        //     axisTooltip.dx = -5;
            
        //     axisTooltip.strokeWidth = 1;
        //     axisTooltip.tensionX = 5;

        //     dropShadow = new am4core.DropShadowFilter();
        //     dropShadow.dy = 1;
        //     dropShadow.dx = 1;
        //     dropShadow.opacity = 0.5;
        //     axisTooltip.filters.push(dropShadow);

        //     chart.cursor = new am4charts.XYCursor();
        //     series.riseFromPreviousState.properties.fillOpacity = 1;
        //     series.dropFromPreviousState.properties.fillOpacity = 0;

        //     // a separate series for scrollbar
        //     let lineSeries = chart.series.push(new am4charts.LineSeries());
        //     lineSeries.dataFields.dateX = "date";
        //     lineSeries.dataFields.valueY = "stock_close";
        //     lineSeries.defaultState.properties.visible = false;
        //     lineSeries.hiddenInLegend = false;
        //     lineSeries.fillOpacity = 0.5;
        //     lineSeries.strokeOpacity = 1;
        //     lineSeries.tensionX = 0.8;
        //     let scrollbarX = new am4charts.XYChartScrollbar();
        //     scrollbarX.series.push(lineSeries);
        //     chart.scrollbarX = scrollbarX;     
        //     // these two lines makes the axis to be initially zoomed-in
        //     // dateAxis.start = 0.7;
        //     // dateAxis.keepSelection = true;    
        //     chart.events.on("ready", function() {
        //         dateAxis.zoomToDates(new Date(2019, 8, 16), new Date(), false, true);
        //     });
        //     chart.cursor.behavior = "none";    
        //     chart.data = this.$store.state.stockData;
        //     chart.cursor.snapToSeries = series;
        //     chart.cursor.xAxis = dateAxis;
        // },
        // createAnalyticChart: function() {
        //     if (this.$router.currentRoute.name !== 'currency') {
        //         return false;
        //     }
        //     // Themes begin
        //     am4core.useTheme(am4themes_animated);
        //     // Themes end

        //     // Create chart instance
        //     let chart = am4core.create("anchartdiv", am4charts.XYChart);
        //     chart.background.visible = false;
        //     chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
        //     let dateAxis = chart.xAxes.push(new am4charts.DateAxis());        
        //     dateAxis.renderer.grid.template.location = 0;
        //     dateAxis.startLocation = 0.5;
        //     dateAxis.endLocation = 0.5;
        //     dateAxis.renderer.fontSize = "14px";
        //     dateAxis.groupData = true;
        //     dateAxis.skipEmptyPeriods = true; 
        //     let axisTooltip = dateAxis.tooltip;
        //     axisTooltip.background.fill = am4core.color("#01518C");
        //     axisTooltip.background.strokeWidth = 0;
        //     axisTooltip.background.cornerRadius = 2;
        //     axisTooltip.background.pointerLength = 5;
        //     axisTooltip.dy = 5;
        //     axisTooltip.fontSize = "13px"

        //     let dropShadow = new am4core.DropShadowFilter();
        //     dropShadow.dy = 1;
        //     dropShadow.dx = 1;
        //     dropShadow.opacity = 0.5;
        //     axisTooltip.filters.push(dropShadow);
        //     // Add data
        //     chart.data = this.$store.state.stockForecastData;                

        //     let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        //     valueAxis.renderer.fontSize = "14px";
        //     valueAxis.tooltip.disabled = true;
        //     // Create series
        //     let series = chart.series.push(new am4charts.LineSeries());
        //     series.dataFields.valueY = "stock_value";
        //     series.dataFields.dateX = "date";
        //     series.tooltipText = "[font-size: 13px]$ {stock_value}[/]";
        //     series.tooltip.tooltipColorSource = false;
        //     series.tooltip.getFillFromObject = false;
        //     series.tooltip.background.fill = am4core.color("#ffffff");
        //     series.tooltip.label.fill = am4core.color("#000000");
        //     series.tooltip.background.stroke = am4core.color("#67B7DC");
        //     series.tooltip.background.strokeWidth = 2;
        //     series.fillOpacity = 0.7;
        //     series.strokeOpacity = 1;
        //     series.strokeWidth = 2;
        //     series.tensionX = 0.9;
        //     // series.tooltip.pointerOrientation = "vertical";

        //     chart.cursor = new am4charts.XYCursor();
        //     chart.cursor.snapToSeries = series;
        //     chart.cursor.xAxis = dateAxis;
        //     chart.cursor.behavior = "none";  
            
        // }
    },
    mounted(){
        this.$store.dispatch('stockData', {page: -1, action: 'getStockData'});
        this.$store.dispatch('stockData', {page: -1, action: 'getStockForecastData'});
        
        // charts
        // this.$store.subscribe((mutation, state) => {
        //     if (mutation.type === 'SET_STOCK_DATA') {
        //         this.createChart();
        //     }
        //     if (mutation.type === 'SET_STOCK_FORECAST_DATA') {
        //         this.createAnalyticChart();
        //     }
        // })
    },
    destroyed() {
        this.$store.subscribe(function(mutation, state) {});
    }
    
}
</script>