<template>    
    <transition name="fade" mode="out-in">
        <div v-if="orderCreated === false" :key="1" class="single-order-wrapper" :class="{'open' : displayBuy === true}">
            <div class="single-order" :class="{'requested' : checkApply, 'mine' : mine, 'closed' : order.status == 2}">
                <div>
                    #{{order.id}}<br v-if="!guest" />
                    <span class="single-order__seller" v-if="!guest">
                        {{order.name.split(' ')[0].split('')[0]}}{{order.name.split(' ')[0].split('')[1]}}<template v-for="a in order.name.split(' ')[0].split('').length - 3">*</template>{{order.name.split(' ')[0].split('')[order.name.split(' ')[0].split('').length - 1]}}<br/>({{order.country}})
                     </span>
                </div>
                <div>{{order.coins}} <span style="font-size: 10px;">{{order.coins_type == 2 ? 'mCRON' : 'CRON'}}</span></div>
                <div>{{order.price}} $</div>
                <div>{{order.date | moment("DD/MM/YYYY")}}</div>
                <div class="desktop" v-if="!guest">
                    <div class="submit narrow" @click="buyOrder">{{openBtnText}}</div>
                </div>
            </div>
            <div class="mobile" v-if="!guest">
                <div class="submit narrow" :class="{'mine' : mine === true}" @click="buyOrder">{{openBtnText}}</div>
            </div>
            <div v-if="displayBuy === true && !guest">
                <template v-if="mine === false">
                    <div class="order-create-form" v-if="checkApply === false">
                        <input-field :type="'number'" :name="'number'" :label="'Number of coins'" 
                            @setNumber="setNumber"></input-field>
                        <p class="subtotal"><span>Subtotal:</span> {{Intl.NumberFormat('de-DE', { style: 'currency', currency: 'USD' }).format(order.price * (coins > 0 ? coins: 0))}}</p>
                        <div class="errors" v-if="displayError === true">
                            <p class="error" style="margin: 0; padding: 0;">{{error.message}}</p>
                        </div>
                        <div class="order-create-buttons">
                            <div class="submit narrow blue mobile" @click="buyOrder">Cancel</div>
                            <div class="submit narrow" @click="applyOrder">Confirm</div>
                        </div>
                    </div>
                    <div class="order-create-form wide" v-else-if="checkApply === true">
                        <div class="verification verififcationSucceed">
                            <div style="text-align: center; line-height: 25px;">
                                <p>You requested to buy {{requestedCoins}} coin(-s) at this order.</p><p>You need to wait Seller to approve your request.</p>
                                <div class="submit narrow"  @click="goBack">Ok</div>
                            </div>                     
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="order-create-form wide">
                        <p style="text-align: center;">This is an order you created.<br/>Below you can see all the requests for purchasing your CRONs</p>
                        <div v-for="(apply, index) in appliedOrders" :key="index" class="applied-order">
                            <application-single :apply="apply" :order="order" @getAppliedOrders="getAppliedOrders" @updateBalance="updateBalance"></application-single>
                        </div>
                        <div class="order-create-buttons" style="margin-top: 20px;">
                            <div class="submit narrow blue mobile" @click="buyOrder">Cancel</div>
                            <div class="submit narrow blue" @click="closeOrder">Close order</div>
                        </div>
                    </div>
                </template>
                
            </div>
        </div>
        <div v-else :key="2" style="width: 100%;">
            <div class="verification verififcationSucceed">
                <div style="text-align: center; line-height: 25px;">
                    <p>You sent a request to close the order.</p><p>Now you need to wait Seller to approve your request.</p>
                    <div class="submit narrow" @click="goBack">Ok</div>
                </div>                     
            </div>
        </div>
    </transition>
</template>
<script>
import input from './Input.vue';
import ApplicationSingle from './ApplicationSingle.vue';
import axios from 'axios';
export default {
    data() {
        return {
            displayBuy: false,
            error: {},
            displayError: false,
            sellPrice: 0,
            coins: 0,
            orderCreated: false,
            checkApply: false,
            requestedCoins: 0,
            appliedOrders: []
        }
    },
    computed: {
        openBtnText() {
            if (this.mine === true) {
                if (this.displayBuy === false) {
                    return 'Open';
                } else {
                    return 'Hide'
                }
            } else if (this.checkApply === true) {
                if (this.displayBuy === false) {
                    return 'Open';
                } else {
                    return 'Hide'
                }
            } else if (this.displayBuy === false) {
                return 'Buy';
            } else {
                return 'Cancel';
            }
        }
    },
    components: {
        'input-field': input,
        'application-single': ApplicationSingle
    },
    props: ['order', 'mine', 'guest'],
    methods: {
        buyOrder() {
            this.displayBuy = !this.displayBuy;
        },
        setNumber: function(number) {
            this.coins = parseInt(number);
        },
        setPrice: function(price) {
            this.sellPrice = parseInt(price);
        },
        goBack() {
            this.$emit('loadOrders');
            this.updateBalance();
            this.buyOrder();
            this.orderCreated = false;
        },
        updateBalance() {
            this.$emit('updateBalance');
        },
        checkApplies() {
            axios.post(this.$store.state.postUrl, {
                action: 'checkApplyOrder',
                order_id: this.order.id
            }, this.$store.state.config).then(response => {
                if (response.data.result === true) {
                    this.checkApply = true;
                    this.requestedCoins = response.data.coins;
                }
            }).catch(e => {
                console.error(e + '\nSome error occured, please, reload the page or contact support')
            })
        },
        applyOrder() {
            if (this.orderApplied === true || this.coins === 0) {
                return false;
            }
            if (this.coins > this.order.coins) {
                this.error = {message: 'Not enough coins in the order'};
                this.displayError = true; 
                setTimeout(() => {
                    this.displayError = false
                }, 3000)
                return false;
            }
            this.orderApplied = true;
            axios.post(this.$store.state.postUrl, {
                action: 'applyOrder',
                coins: this.coins,
                order_id: this.order.id,
                coins_type: this.order.coins_type,
                user_id: this.$store.state.user.id,
                user_ip: this.$store.state.userIp
            }, this.$store.state.config).then(response => {
                this.orderApplied = false;
                if (response.data.result === true) {
                    this.orderCreated = true;
                    this.coins = 0;
                    this.sellPrice = 0;
                    this.checkApplies();
                } else if (response.data.code == 1) {
                    this.displayError= true;
                    this.error = {message: `Not enough units in the order`};
                    setTimeout(() => {
                        this.displayError = false
                    }, 3000)
                }
            }).catch(e => {
                console.error(e + '\nSome error occured, please, reload the page or contact support')
            })
        },
        getAppliedOrders() {
            if (this.mine === true) {
                axios.post(this.$store.state.postUrl, {
                    action: 'getAppliedOrders',
                    order_id: this.order.id
                }, this.$store.state.config).then(response => {
                    this.appliedOrders = response.data;
                    this.$emit('loadOrders');
                    this.updateBalance();
                }).catch(e => {
                    console.error(e + '\nSome error occured, please, reload the page or contact support')
                })
            } 
        },
        closeOrder() {
            let confirmation = confirm("Are you sure to delete this order? All applied requests will be declined");
            if (confirmation === true) {
                axios.post(this.$store.state.postUrl, {
                    action: 'closeOrder',
                    order_id: this.order.id
                }, this.$store.state.config).then(response => {
                    if (response.data.result === true) {
                        this.$emit('loadOrders');
                        this.updateBalance();
                    }
                }).catch(e => {
                    console.error(e + '\nSome error occured, please, reload the page or contact support')
                })
            }
        },
    },
    created() {
        this.checkApplies();
        this.getAppliedOrders();
    }
}
</script>