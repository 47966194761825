<template>
    <div class="center">
        <div class="article internal fake">
            <router-link class="article__add" :to="{ path: `article/add/new`}">Add new</router-link>
            <article-widget :limit="articlesPerPage" :showCategory="true" :edit="true" :page="page"></article-widget>
            <div class="stock-pagination">
                <span v-if="page > 1" @click="page--" >Prev</span>
                <span class="deactive">{{page}}</span>
                <span v-if="articlesNumber > page * articlesPerPage" @click="page++">Next</span>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import ArticleWidget from './ArticleWidget.vue';
export default {
    data() {
        return {
            articlesPerPage: 30,
            page: 1,
            articlesNumber: 0
        }
    },
    components: {
        'article-widget': ArticleWidget
    },
    mounted() {
        axios.post(this.$store.state.postUrl, {
            action: 'getArticlesNumber'
        }, this.$store.state.config).then(response => {
            this.articlesNumber = response.data;
        }).catch(e => {
            console.error('Some error occured, please, reload the page or contact support')
        })
    }
}
</script>