<template>
    <div class="article internal">
        <template v-if="article != null">
            <h1 class="article__header">{{page}}</h1>
            <div class="article__date_publish" v-if="article.show_info == 1">
                <span>Published in {{article.category.name}}, {{article.date_create | moment("HH:mm")}} {{article.date_create | moment("DD/MM/YYYY")}}</span>
            </div>
            <div class="text_full" v-html="article.text_full">
            </div>
            <div class="articles__buttons articles__buttons-bottom"> 
                <div class="btn-cancel cancel" @click="goBack">Back</div>
            </div>
        </template>
        
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            page: "Page name",
            article: null
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        }
    },
    mounted() {
        axios.post(this.$store.state.postUrl, {
            action: 'getArticleData',
            category: this.$route.params.category,
            article: this.$route.params.url
        }, this.$store.state.config).then(response => {
            if (response.data.result === false) {
                this.$router.push({ name: '404'})
            }
            console.warn(response.data)
            this.article = response.data[0];
            this.page = this.article.name;
        })
    }
}
</script>