<template>
    <transition name="fade" mode="out-in">
        <div v-if="orderCreated === false" :key="1">
            <div class="order-create-form">
                <div class="order-create-line">
                    <input-field :type="'number'" :name="'number'" :label="'Number of units'" 
                    @setNumber="setNumber"></input-field>
                    <select @change="setCoinsType">
                        <option value="1">CRON</option>
                        <option value="2" v-if="false">mCRON</option>
                    </select>
                </div>
                <input-field :type="'number'" :name="'price'" :label="'Desired price'" 
                    @setPrice="setPrice"></input-field>
                <p class="subtotal"><span>Subtotal:</span> {{Intl.NumberFormat('de-DE', { style: 'currency', currency: 'USD' }).format(sellPrice * coins)}}</p>
                <transition name="fade" mode="out-in">
                    <div class="errors" v-if="displayError === true">
                        <p class="error" style="margin: 0; padding: 0;">{{error.message}}</p>
                    </div>
                </transition>
                <div class="submit" @click="sellCoins">{{buttonText}}</div>
            </div>
        </div>
        <div v-else :key="2">
            <div class="verification verififcationSucceed">
                <div style="text-align: center; line-height: 25px;">
                    <p>Your order has been created successfully!</p>
                    <div class="submit narrow"  @click="goBack">Back</div>
                </div>                     
            </div>
        </div>
    </transition>
</template>
<script>
import axios from 'axios';
import input from './Input.vue';
export default {
    data() {
        return {
            orderCreated: false,
            coins: 0,
            sellPrice: 0,
            coinsType: 1,
            displayError: false,
            inProgress: false,
            error: null            
        }
    },
    computed: {
        buttonText() {
            return this.inProgress ? 'Please, wait...' : 'Create order';
        }
    },
    props: ['balance', 'mBalance'],
    methods: {
        setNumber: function(number) {
            this.coins = parseInt(number);
        },
        setPrice: function(price) {
            this.sellPrice = parseInt(price);
        },
        setCoinsType (e) {
            this.coinsType = +e.target.value
        },
        sellCoins() {
            if (this.inProgress) {
                return false;
            }
            let isError = false;
            this.displayError = false;
            if (this.coinsType === 1) {
                if (this.balance < this.coins) {
                    isError = true;
                    this.error = {message: `You don't have this amount of CRON`};
                }
            } else if (this.mBalance < this.coins) {
                isError = true;
                this.error = {message: `You don't have this amount of mCRON`};
            }
            if (this.coins <= 0) {
                isError = true;
                this.error = {message: `Amount of units is wrong`};
            } else if (this.sellPrice <= 0) {
                isError = true;
                this.error = {message: `Price must be more, than 0$`};
            }
            if (isError === true) {
                this.displayError = true;
                setTimeout(() => {
                    this.displayError = false
                }, 3000)
                return false;
            }            
            this.inProgress = true;
            axios.post(this.$store.state.postUrl, {
                action: 'createOrder',
                type: 2,
                coins: this.coins,
                price: this.sellPrice,
                coins_type: this.coinsType,
                user_ip: this.$store.state.userIp
            }, this.$store.state.config).then(response => {                
                this.inProgress = false;
                if (response.data.result === true) {
                    this.orderCreated = true;
                    this.$store.commit('SET_USER_BALANCE', {balance: response.data.balance, frozen: response.data.frozen});
                    this.coins = 0;
                    this.sellPrice = 0;
                    this.$store.dispatch('loadBuyOrders', {type: 2, page: 1, user_id: null, page_limit: 30});
                    this.$store.dispatch('loadBuyOrders', {type: 2, page: 1, user_id: this.$store.state.user.id, page_limit: 30});
                } else {
                    if (response.data.code == 1) {
                        this.displayError= true;
                        this.error = {message: `You don't have this amount of CRON`};
                        setTimeout(() => {
                            this.displayError = false
                        }, 3000)
                    }
                }
            }).catch(e => {
                console.error(e + ': Some error occured, please, reload the page or contact support')
            })
        },
        goBack() {
            this.orderCreated = false;
        }
    },
    components: {
        'input-field': input
    },
    mounted() {
    }
}
</script>