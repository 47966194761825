<template>
    <div class="articles__single">
        <div class="article__name">
            <router-link v-if="!edit" :to="{ name: 'article', params: {url: article.url, category: article.category.url}}">{{article.name}}</router-link>
            <router-link v-if="edit" :to="{ path: `edit/${article.category.url}/${article.url}`}">{{article.name}}</router-link>
        </div>
        <div v-if="showCategory" class="article__category">{{article.category.name}}</div>
        <div class="article__date">{{/*article.date_create | moment("HH:mm")*/}}{{article.date_edit | moment("DD/MM/YYYY")}}</div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            firstLoad: true
        }
    },
    props: ['article', 'showCategory', 'edit']
}
</script>