<template>
    <div class="article internal">
        <h1>{{page}}</h1>
        <p>
            These Terms of Service ("Terms", "Terms of Service") govern your relationship with www.climatecron.com website (the "Service") operated under advisory contract by Climate Global Control Trading LLC, the sole and rightful owner (referred to in this document as “Evarei”, "us", "we", or "our").
            Please read these Terms of Service carefully before using the Service. Your access to and use of the Service conditional upon on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.
            By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may choose to not access the Service.
        </p>
        <h2>Purchases</h2>    
        <p> 
            If you wish to purchase any product or service made available through the Service ("Purchase"), you may be asked to supply certain information relevant to your Purchase including, without limitation, data pertaining to your financial assets and personal information.
        <p>    You represent and warrant that: (i) you have the legal right to use issue and transfer such financial assets or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.
        <p>    By submitting such information, you grant us the right to provide the information to third parties for the purpose of facilitating the completion of Purchases and verifying compliance with relevant laws, acts and bills.
        <p>    We reserve the right to refuse or cancel your order at any time for certain reasons including but not limited to: product or service availability, errors in the description or price of the product or service or error in your order. We are under no obligation to cite reasons for such cancellation refusal or rejection.
        <p>   We reserve the right to refuse, cancel or reject your order if fraud or an unauthorized or illegal transaction is suspected.
        </p>
        <h2>Availability, Errors and Inaccuracies</h2>
        <p>
            We are constantly updating our offerings of products and services on the Service. The products or services available on our Service may be mispriced, described inaccurately, or unavailable, and we may experience delays in updating information on the Service and in our advertising on other websites.
        <p>    We cannot and do not guarantee the accuracy or completeness of any information, including prices, bonuses, discounts, volumes of products and services that are available, product images, timelines, dates, progress updates, specifications, availability, and services. We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.
        </p>
        <h2>Accounts</h2> 
        <p>
            When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.
        <p>    You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.
        <p>    You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.
        <p>    You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trade mark that is subject to any rights of another person or entity other than you without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.
        <p>    Only you are permitted to use your account and the information provided will be used by us to identify and match the user of your account. Allowing anyone else to use your account and send digital assets or funds to us may be a criminal offence and subject to legal and/or criminal action against you.
        </p>
        <h2>Intellectual Property</h2>    
        <p>
            The Service and its original content, features and functionality are and will remain the exclusive property of Climate Cron, the sole and rightful owner and its clients and licensors. The Service is protected by copyright, trademark, and other laws of the Cayman Islands, and foreign countries. Our trademarks and trade address may not be used in connection with any product or service without the prior written consent of Climate Global Control Trading LLC and the Services herein.
        </p>
        <h2>Links to Other Web Sites</h2>
        <p>
            Our Service may contain links to third-party web sites or services that are not owned or controlled by Climate Global Control Trading LLC.
        <p>    Climate Global Control Trading LLC has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that Climate Global Control Trading LLC shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.
        <p>    We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.
        </p>
        <h2>Termination</h2>
        <p>
            We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
        <p>    Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.
        </p>    
        <h2>Limitation of Liability</h2>
        <p>
            In no event shall Climate Global Control Trading LLC, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
        </p>
        <h2>Disclaimer</h2>
        <p>
            Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance. Climate Global Control Trading LLC its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.
        <p>    All Subscribers and their digital assets used to partake in this offering of securities shall be subject to know your client, anti-money laundering and combatting of the financing of terrorism checks by the Issuer and their third party service providers and advisors. If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.
        </p>
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page: "Terms of Use"
        }
    }
}
</script>