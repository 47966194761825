<template>
    <div class="article internal">
        <h1>{{page}}</h1>
        <p>Feel free to communicate with us, using following email addresses:</p>
        <p>
            <span><a href="mailto:info@climatecron.com" style="color:#144E8C;">info@climatecron.com</a></span> <span style="font-style: italic;">- general questions;</span>
        </p>
        <p>
            <span><a href="mailto:support@climatecron.com" style="color:#144E8C;">support@climatecron.com</a></span> <span style="font-style: italic;">- technical issues;</span>
        </p>
        <p>
            <span><a href="mailto:finance@climatecron.com" style="color:#144E8C;">finance@climatecron.com</a></span> <span style="font-style: italic;">- financial enquiries;</span>
        </p>
        <p>
            <a href="tel:+971585430133" style="color:#144E8C; text-decoration: none;">+(971) 585430133</a> - Customer Service<br>
            <a href="https://wa.me/:+971526851133" style="color:#144E8C; text-decoration: none;">+(971) 526851133</a> - Whatsapp
        </p>
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page: "Contact info"
        }
    }
}
</script>