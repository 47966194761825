<template>
    <div>
        <div class="article wide">        
            <div class="article__columns">
                <div>
                    <h2>What is Climate CRON</h2>
                    <p>The competition of modern financial technology actually boils down to competition between different management models.</p>
                    <p class="quote">This is the first financial instrument in the world, provided by a real asset – fresh water<span class="quotes"></span></p>
                    <p>The concept of monetization of global market of water resources, based on the creation and development of ecosystem of "Climate CRON", provided by climate services, is a unique and innovative model of management of the economy.</p>
                </div>  
            </div>   
            <div class="slider" ref="slider">
                <div class="slide" v-for="(slide, index) in slides" :key="index" :style="{'opacity' : slide.opacity}"></div>
            </div>
        </div>
        <div class="article wide">        
            <div class="article__columns">
                <div>
                    <p>This management model will allow major holders of "Climate CRON" to effectively manage of global market of water resources by regulating the market rate of "Climate CRON", increasing or reducing the sentence of "Climate CRON".</p>
                </div>   
                <div>
                </div>
            </div>   
        </div>
    </div>   
</template>
<script>
export default {
    data() {
        return {
            page: "Main",
            slides: [{opacity: 1},{opacity: 0},{opacity: 0}]
        }
    },
    methods: {
        runSlider: function(current, next) {
            setTimeout(() => {
                this.slides[current].opacity = 0;
                this.slides[next].opacity = 1;
                this.runSlider(next, next + 1 < this.slides.length ? next + 1 : 0);
            }, 3500)
        }
    },
    mounted() {
        this.runSlider(0, 1);
    }
}
</script>