/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies';
import Vue2TouchEvents from 'vue2-touch-events'
import { directive as onClickOutside } from 'vue-on-click-outside' 
import axios from 'axios';
Vue.directive('on-click-outside', onClickOutside);
Vue.use(Vuex)
Vue.use(VueCookies)
Vue.use(Vue2TouchEvents, {
  disableClick: false,
  touchClass: '',
  tapTolerance: 10,
  swipeTolerance: 30,
  longTapTimeInterval: 400
})

export default new Vuex.Store({
  state: {
    postUrl: 'https://climatecron.com/data/',
    config: {
      headers: {    
        'Content-Type': 'application/x-www-form-urlencoded',
        // 'Content-Type': 'multipart/form-data',
        'Accept': 'application/json'
      }
    },
    user: {status: false, balance: 0, frozen: 0, mBalance: 0, mFrozen: 0},
    userIp: null,
    checkIp: false,
    newUser: {},
    scrollTop: 0,
    registerStatus: 0,
    course: 1000,
    userChecked: false,
    restoreEmail: "",
    stockData: [],
    stockForecastData: [],
    marketData: [],
    articlesData: [],
    article: {},
    buyOrderData: [],
    myOrderData: [],
  },
  getters: {
    scrollTop: state => {
      return state.scrollTop;
    },
    registerStatus: state => {
      return state.registerStatus;
    },
    user: state => {
      return state.user;
    }
  },
  mutations: {
    UPDATE_SCROLL(state, top) {
      state.scrollTop = top;
    },
    SET_REGISTER_STATUS(state, status) {
      state.registerStatus = status;
    },
    SET_STOCK_DATA(state, stockData) {
      state.stockData = stockData;
    },
    SET_STOCK_FORECAST_DATA(state, stockData) {
      state.stockForecastData = stockData;
    },
    SET_CURRENCY_DATA(state, currencyData) {
      state.currencyData = currencyData;
    },
    SET_MARKET_DATA(state, marketData) {
      state.marketData = marketData;
    },
    SET_ARTICLES_DATA(state, articlesData) {
      state.articlesData = articlesData;
    },
    SET_ARTICLE_DATA(state, articleData) {
      state.article = articleData;
    },
    SET_USER_BALANCE(state, params) {
      state.user.balance = params.balance;
      state.user.frozen = params.frozen;
      state.user.mBalance = params.mBalance;
      state.user.mFrozen = params.mFrozen;
    },
    SET_BUY_ORDER_DATA(state, params) {
      state.buyOrderData = params;
    },
    SET_MY_ORDER_DATA(state, params) {
      state.myOrderData = params;
    }
  },
  actions: {    
    // user 
    checkUserAuthorized() {
      this.dispatch('getUserIp', 'checkUser');
    },
    startUserRegistration() {
      this.dispatch('getUserIp', 'registerUser');
    },
    startUserSaving() {
      this.dispatch('getUserIp', 'saveUser');
    },
    startUserLogin() {
      this.dispatch('getUserIp', 'loginUser')
    },
    startUserRestore({commit, state}, email) {
      state.restoreEmail = email;
      this.dispatch('getUserIp', 'restoreUser')      
    },

    getUserIp({commit, state}, nextStep) {
      commit('SET_REGISTER_STATUS', 0)
      axios.post(state.postUrl, {
        action: "getIp",
      }).then(response => {       
        state.userIp = response.data.ip;
        state.checkIp = true;
        this.dispatch(nextStep);
      }).catch(e => {
        console.error('Some error occured, please, reload the page or contact support')
        commit('SET_REGISTER_STATUS', 10);
        state.userIp = null;
        state.checkIp = false;
      });
    },

    checkUser({commit, state}) {
      commit('SET_REGISTER_STATUS', 0)
      axios.post(state.postUrl, {
        action: "checkAuthorized",
        user_ip: state.userIp,
        email: VueCookies.get("email") ? VueCookies.get("email") : null,
        login_hash: VueCookies.get("login_hash") ? VueCookies.get("login_hash") : null
      }, state.config).then(response => {
        if (response.data.result === true) {
          state.user.id = response.data.id;
          state.user.email = response.data.user;
          state.user.wallet = response.data.wallet;
          state.user.name = response.data.name;
          state.user.phone = response.data.phone;
          state.user.country = response.data.country;
          state.user.balance = response.data.balance;
          state.user.frozen = response.data.frozen;
          state.user.mBalance = response.data.mBalance;
          state.user.mFrozen = response.data.mFrozen;
          state.user.rights = response.data.rights;
          state.user.status = response.data.status;
          commit('SET_REGISTER_STATUS', 1)
        } else {
          VueCookies.remove("email");
          VueCookies.remove("login_hash")
        }
        state.userChecked = true; 
      }).catch(e => {
        console.error('Some error occured, please, reload the page or contact support')
      })
    },    

    registerUser({commit, state}) {
      commit('SET_REGISTER_STATUS', 0)
      axios.post(state.postUrl, {
        action: "registerUser",
        user_ip: state.userIp,
        email: state.newUser.email,
        password: state.newUser.password,
        name: state.newUser.name,
        country: state.newUser.country,
        phone: state.newUser.phone
      }, state.config).then(response => {
        if (response.data.result) {
          commit('SET_REGISTER_STATUS', 1)
        } else {
          commit('SET_REGISTER_STATUS', +response.data.code)
        }
      }).catch(e => {
        commit('SET_REGISTER_STATUS', 10)
        console.error('Some error occured, please, reload the page or contact support')
      })
    },

    saveUser({commit, state}) {
      commit('SET_REGISTER_STATUS', 0)
      axios.post(state.postUrl, {
        action: "saveUser",
        user_ip: state.userIp,
        password: state.newUser.password || "",
        name: state.newUser.name,
        country: state.newUser.country,
        phone: state.newUser.phone,
        email: VueCookies.get("email") ? VueCookies.get("email") : null,
        login_hash: VueCookies.get("login_hash") ? VueCookies.get("login_hash") : null,
      }, state.config).then(response => {
        if (response.data.result) {
          commit('SET_REGISTER_STATUS', 1)
        } else {
          commit('SET_REGISTER_STATUS', +response.data.code)
        }
      }).catch(e => {
        commit('SET_REGISTER_STATUS', 10)
        console.error('Some error occured, please, reload the page or contact support')
      })
    },

    activateUser({commit, state}, link) {
      axios.post(state.postUrl, {
        action: "activateUser",
        link: link
      }, state.config).then(response => {
        if (response.data.result) {
          commit('SET_REGISTER_STATUS', 1) 
        } else {
          commit('SET_REGISTER_STATUS', +response.data.code)
        }
      }).catch(e => {
        commit('SET_REGISTER_STATUS', 10);
        console.error('Some error occured, please, reload the page or contact support')
      })
    },

    loginUser({commit, state}, data) {
      commit('SET_REGISTER_STATUS', 0)
      axios.post(state.postUrl, {
        action: "loginUser",
        email: data.email,
        password: data.password,
        userIp: state.userIp
      }, state.config).then(response => {
        if (response.data.result) {
          commit('SET_REGISTER_STATUS', 1);
          state.user.id = response.data.id;
          state.user.wallet = response.data.wallet;
          state.user.email = response.data.user;
          state.user.name = response.data.name;
          state.user.phone = response.data.phone;
          state.user.country = response.data.country;
          state.user.balance = response.data.balance;
          state.user.frozen = response.data.frozen;
          state.user.mBalance = response.data.mBalance;
          state.user.mFrozen = response.data.mFrozen;
          state.user.rights = response.data.rights;
          state.user.status = response.data.status;
          VueCookies.set("email", response.data.user);
          VueCookies.set('login_hash', response.data.user_hash);
        } else if (response.data.code == 5) {
          commit('SET_REGISTER_STATUS', +response.data.code);
          state.user.status = false;
        } else if (response.data.code == 6) {
          commit('SET_REGISTER_STATUS', +response.data.code);
          state.user.status = false;
        }
      }).catch(e => {
        commit('SET_REGISTER_STATUS', 10);
        console.error('Some error occured, please, reload the page or contact support')
      })
    },

    logoutUser({commit, state}) {
      commit('SET_REGISTER_STATUS', 0)
      VueCookies.remove("email");
      VueCookies.remove("login_hash");
      state.user.status = false;
    },

    restoreUser({commit, state}) {
      commit('SET_REGISTER_STATUS', 0)
      axios.post(state.postUrl, {
        action: "restoreUser",
        email: state.restoreEmail,
        userIp: state.userIp
      }, state.config).then(response => {
        if (response.data.result) {
          commit('SET_REGISTER_STATUS', 1) 
        } else {
          commit('SET_REGISTER_STATUS', +response.data.code)
        }
      }).catch(e => {
        commit('SET_REGISTER_STATUS', 10);
        console.error('Some error occured, please, reload the page or contact support')
      })
    },

    resetUser({commit, state}, link) {
      axios.post(state.postUrl, {
        action: "resetUser",
        hash_link: link
      }, state.config).then(response => {
        if (response.data.result) {
          commit('SET_REGISTER_STATUS', 1) 
        } else {
          commit('SET_REGISTER_STATUS', +response.data.code)
        }
      }).catch(e => {
        commit('SET_REGISTER_STATUS', 10);
        console.error('Some error occured, please, reload the page or contact support')
      })
    },

    updateBalance({commit, state}) {
      axios.post(state.postUrl, {
        action: "updateUserBalance"
      }, state.config).then(response => {
        if (response.data.result === true) {
          commit('SET_USER_BALANCE', {
            'balance': response.data.balance, 
            'frozen': response.data.frozen,
            'mBalance': response.data.mBalance,
            'mFrozen': response.data.mFrozen
          })
        }
      }).catch(e => {
        commit('SET_REGISTER_STATUS', 10);
        console.error('Some error occured, please, reload the page or contact support')
      })
    },

    // common things
    updateScroll({commit}, top) {
      commit('UPDATE_SCROLL', top)
    },

    // stock
    stockData({commit, state}, params) {
      axios.post(state.postUrl, {
        action: params.action,
        page: params.page
      }, state.config).then(response => {
        if (params.action === 'getStockData') {
          commit('SET_STOCK_DATA', response.data);
        } else if (params.action === 'getStockForecastData') {
          commit('SET_STOCK_FORECAST_DATA', response.data);
        }
      }).catch(e => {
        commit('SET_REGISTER_STATUS', 10);
        console.error('Some error occured, please, reload the page or contact support')
      })
    },

    saveStockData({commit, state}, params) {
      axios.post(state.postUrl, {
        action: params.action,
        page: params.page,
        stock_id: params.stock_id,
        stock_open: params.stock_open,
        stock_close: params.stock_close,
        stock_high: params.stock_high,
        stock_low: params.stock_low,
        stock_date: params.stock_date
      }, state.config).then(response => {
        commit('SET_STOCK_DATA', response.data);
      }).catch(e => {
        commit('SET_REGISTER_STATUS', 10);
        console.error('Some error occured, please, reload the page or contact support')
      })
    },

    saveStockForecastData({commit, state}, params) {
      axios.post(state.postUrl, {
        action: params.action,
        page: params.page,
        stock_id: params.stock_id,
        stock_value: params.stock_value,
        stock_date: params.stock_date
      }, state.config).then(response => {
        commit('SET_STOCK_FORECAST_DATA', response.data);
      }).catch(e => {
        commit('SET_REGISTER_STATUS', 10);
        console.error('Some error occured, please, reload the page or contact support')
      })
    },

    addStockData({commit, state}, params) {
      axios.post(state.postUrl, {
        action: params.action,
        page: params.page
      }, state.config).then(response => {
        if (params.action === 'addStockData') {
          commit('SET_STOCK_DATA', response.data);
        } else if (params.action === 'addStockForecastData') {
          commit('SET_STOCK_FORECAST_DATA', response.data);
        }
      }).catch(e => {
        commit('SET_REGISTER_STATUS', 10);
        console.error('Some error occured, please, reload the page or contact support')
      })
    },

    deleteStockData({commit, state}, params) {
      axios.post(state.postUrl, {
        action: params.action,
        page: params.page,
        stock_id: params.stock_id
      }, state.config).then(response => {
        if (params.action === 'deleteStockData') {
          commit('SET_STOCK_DATA', response.data);
        } else if (params.action === 'deleteStockForecastData') {
          commit('SET_STOCK_FORECAST_DATA', response.data);
        }
      }).catch(e => {
        commit('SET_REGISTER_STATUS', 10);
        console.error('Some error occured, please, reload the page or contact support')
      })
    },

    // currency 
    currencyData({commit, state}, params) {
      axios.post(state.postUrl, {
        action: 'getCurrencyData',
        currency: params ? params.currency ? params.currency : null : null
      }, state.config).then(response => {
        commit('SET_CURRENCY_DATA', response.data);
      }).catch(e => {
        commit('SET_REGISTER_STATUS', 10);
        console.error('Some error occured, please, reload the page or contact support')
      })
    },

    saveCurrencyData({commit, state}, params) {
      axios.post(state.postUrl, {
        action: 'setCurrencyData',
        dollar: params[0],
        euro: params[1],
        aed: params[2],
        cny: params[3],
      }, state.config).then(response => {
        commit('SET_CURRENCY_DATA', response.data);
      }).catch(e => {
        commit('SET_REGISTER_STATUS', 10);
        console.error('Some error occured, please, reload the page or contact support')
      })
    },

    // market
    marketData({commit, state}) {
      axios.post(state.postUrl, {
        action: 'getMarketData'
      }, state.config).then(response => {
        commit('SET_MARKET_DATA', response.data);
      }).catch(e => {
        commit('SET_REGISTER_STATUS', 10);
        console.error('Some error occured, please, reload the page or contact support')
      })
    },
    saveMarketData({commit, state}, params) {
      axios.post(state.postUrl, {
        action: 'setMarketData',
        bought: params[0],
        available: params[1]
      }, state.config).then(response => {
        commit('SET_MARKET_DATA', response.data);
      }).catch(e => {
        commit('SET_REGISTER_STATUS', 10);
        console.error('Some error occured, please, reload the page or contact support')
      })
    },

    // some from orders 
    loadBuyOrders({commit, state}, params) {
      axios.post(state.postUrl, {
          action: 'getOrders',
          type: params.type,
          guest: params.guest,
          page: params.page,
          user_id: params.user_id || null,
          page_limit: params.page_limit
      }, state.config).then(response => {
        if (params.user_id !== null) {
          commit('SET_MY_ORDER_DATA', response.data);
        } else {
          commit('SET_BUY_ORDER_DATA', response.data);
        }
      }).catch(e => {
        console.error('Some error occured, please, reload the page or contact support')
      })
  },
  }
})
