<template>
    <div class="register__user">
        <h1>{{registerHeader}}</h1>
        <transition name="fade" mode="out-in">
            <form key="1" v-if="registerStatus === 0">
                <input-field :type="'text'" :name="'email'" 
                    :inputError="formErrors.email" 
                    :inputValue="email"
                    :label="'Email'"
                    @setEmail="setEmail"></input-field>
                <input-field :type="'password'" :name="'password'" 
                    :inputError="formErrors.password" 
                    :inputValue="password"
                    :label="'Password'" 
                    @setPassword="setPassword">
                </input-field>
                <input-field :type="'password'" :name="'repeat-password'" 
                    :inputError="formErrors.passwordRepeat" 
                    :inputValue="passwordRepeat"
                    :label="'Repeat password'" 
                    @setPasswordRepeat="setPasswordRepeat">
                </input-field>
                <input-field :type="'text'" :name="'name'" 
                    :inputError="formErrors.name"
                    :inputValue="name"
                    :label="'Your name'"
                    @setName="setName"></input-field>
                <input-field :type="'text'" :name="'phone'" :label="'Contact phone'"
                    @setPhone="setPhone"></input-field>
                <div class="sub_dropdown" v-on-click-outside="hideCountries">
                    <input type="text" name="country" ref="country"
                        :class="{'error': formErrors.country}"
                        :inputValue="country"
                        @change="showCountries" 
                        @keyup="showCountries"
                        @focusin="showCountries"
                        @focusout="unshiftSpan"
                    >
                    <span>Country</span>
                    <div class="dropdown_list" v-if="isCountryListVisible">
                        <div class="dropdown_item" v-for="(country, index) in filteredCountries" 
                            :key="index"
                            @click="fillCountry">
                            <span v-html="country"></span>
                        </div>
                    </div>
                </div>
                <div class="sub_checkbox" :class="{'error': formErrors.agree}">
                    <input type="checkbox" name="agree" id="agree" :checked="isChecked" @change="setAgree">
                    <label for="agree">I agree <router-link :to="{ name: 'terms'}"> Terms of use</router-link></label>
                </div>
                <transition name="fade">
                    <div class="errors" v-if="displayErrors">
                        <div class="error" v-for="(error, index) in registerErrors" :key="index">
                            {{error.message}}
                        </div>
                    </div>
                </transition>                
                <div class="submit" 
                    :class="{'registerInProgress' : registerInProgress}"
                    @click="registerUser"
                >Register</div>  
                <div class="sub_links">
                    <span>Already registered?</span>
                    <router-link :to="{ name: 'login'}">login</router-link>
                </div>
            </form>
            <div key="2" class="registration__progress" v-if="registerStatus === 1"
                :class="{'registerSucceed' : registerStatus === 1}">
                <p>We have sent a link yo your email, please, follow it to activate your account.</p>
            </div> 
        </transition>     
    </div>
    
</template>
<script>
const countries = require('country-list');
import input from './Input.vue';
export default {
    data() {
        return {
            filteredCountries: [],
            isCountryListVisible: false,
            registerInProgress: false,
            registerStatus: 0,
            registerHeader: 'Create an account',
            formErrors: {
                country: false, 
                passwordRepeat: false, 
                password: false,
                email: false,
                name: false,
                agree: false
            },
            password: "",
            passwordRepeat: "",
            email: "",
            name: "",
            country: "",
            phone: "",
            registerErrors: [],
            displayErrors: false,
            isChecked: true
        }
    },
    components: {
        'input-field': input
    },
    methods: {
        showCountries: function(e) {
            this.shiftSpan(e);
            let tempCountries = countries.getNames().filter(country => country.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1);
            if (tempCountries.length === 0) {
                tempCountries.push("<span class='no-matches'>No countries found</span>")
            }
            this.filteredCountries = tempCountries;
            this.formErrors.country = false;
            if (e.target.value.toString().length > 0) {
                this.isCountryListVisible = true;
            } else {
                this.isCountryListVisible = false;
            }
        },
        hideCountries: function() {
            if (this.isCountryListVisible) {
                this.isCountryListVisible = false;
                this.checkCorrectCountry();
            }
        },
        fillCountry: function(e) {
            if (!e.target.classList.contains('no-matches')) {
                this.$refs.country.value = e.target.innerText;
                this.country = e.target.innerText;
            }            
            this.hideCountries();
        },
        setEmail: function(email) {
            this.email = email;
            this.checkEmail();
        },
        setPassword: function(pwd) {
            this.password = pwd;
            this.checkCorrectPasswords();
        },
        setPasswordRepeat: function(pwd) {
            this.passwordRepeat = pwd;
            this.checkCorrectPasswords();
        },
        setName: function(name) {
            this.name = name;
            this.checkName();
        },
        setPhone: function(phone) {
            this.phone = phone;
        },
        setAgree: function(e) {
            this.isChecked = e.target.checked;
            if (this.formErrors.agree && e.target.checked) {
                this.formErrors.agree = false;
            }
        },
        checkCorrectCountry: function() {
            let foundCoutry = countries.getNames().find(country => {
                return country.toLowerCase() === this.$refs.country.value.toLowerCase()
            })
            if (foundCoutry) {
                this.formErrors.country = false;
            } else {
                this.formErrors.country = true;
            }
        },
        checkCorrectPasswords: function() {
            this.formErrors.password = false;
            this.formErrors.passwordRepeat = false;
            if (this.password.trim() === "") {
                this.formErrors.password = true;
            } 
            if (this.passwordRepeat.trim() === "") {
                this.formErrors.passwordRepeat = true;
            }
            if (this.password !== this.passwordRepeat) {
                this.formErrors.password = true;
                this.formErrors.passwordRepeat = true;
            }
        },    
        checkAgree: function() {
            this.formErrors.agree = false;
            if (!this.isChecked) {
                this.formErrors.agree = true;
            }
        },    
        checkEmail: function() {
            let pattern = /^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/i;
            if (pattern.test(this.email)) {
                this.formErrors.email = false;
            } else {
                this.formErrors.email = true;
            }
            this.updateError('email');
        },
        checkName: function() {
            if (this.name.trim() !== "") {
                this.formErrors.name = false;
            } else {
                this.formErrors.name = true;
            }
        },
        shiftSpan: function(e) {
            e.target.nextSibling.style.top = "-6px";
            e.target.nextSibling.style.fontSize = "10px";
        },
        unshiftSpan: function(e) {
            if (e.target.value.trim() === "") {
                e.target.nextSibling.style.top = "10px";
                e.target.nextSibling.style.fontSize = "13px";
            }            
        },
        registerUser: function() {
            if (this.registerInProgress) {
                return false;
            }
            this.displayErrors = false;
            this.checkEmail();
            this.checkCorrectPasswords();
            this.checkCorrectCountry();
            this.checkName();
            this.checkAgree();
            let noErrors = true;
            for (const key of Object.keys(this.formErrors)) {
                const error = this.formErrors[key];          
                if (error === true) {
                    noErrors = false;
                }      
            }
            if (noErrors) {
                this.$store.state.newUser.name = this.name;
                this.$store.state.newUser.password = this.password;
                this.$store.state.newUser.email = this.email;
                this.$store.state.newUser.phone = this.phone;
                this.$store.state.newUser.country = this.country;
                this.registerInProgress = true;
                this.$store.dispatch('startUserRegistration');
            }
        },
        changeRegisterStatus: function(st) {
            setTimeout(() => {                
                if (st === 1) {
                    this.registerStatus = st;
                    this.registerHeader = "Your account has been created";
                } else if (st === 2) {
                    this.displayErrors = true;
                    this.registerErrors = [];
                    this.registerErrors.push({type: 'email', message: "This email is already registered, choose another one or restore password"});
                    this.registerStatus = 0;
                    this.registerInProgress = false;                 
                } else if (st === 10) {
                    this.displayErrors = true;
                    this.registerErrors = [];
                    this.registerErrors.push({type: 'name', message: "No connection. Code 401."});
                    this.registerStatus = 0;
                    this.registerInProgress = false;   
                }
            }, 500)            
        },
        updateError: function(type) {
            for (let i = this.registerErrors.length - 1; i >= 0; i--) {
                if (this.registerErrors[i].type === type) {
                    this.registerErrors.splice(i, 1);
                }
            }
            if (this.registerErrors.length === 0) {
                this.displayErrors = false;
            } 
        }
    },
    mounted() {
        if (this.$store.state.user.status) {
            this.$router.push({ name: 'personal'})
        }
        this.$store.commit("SET_REGISTER_STATUS", 0)
        this.$store.watch(
            state => this.changeRegisterStatus(state.registerStatus)
        );
    }
}
</script>