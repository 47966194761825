<template>
    <div class="user-menu" :class="{'admin-menu' : userRights == 125}">
        <div class="user-ip">Hello, {{$store.state.user.name}}</div>
        <div v-if="userRights < 100" :class="{'active': activeTab == 'trade'}" @click="switchTab('trade')">Trade</div>
        <div v-if="userRights < 100" :class="{'active': activeTab == 'settings'}" @click="switchTab('settings')">Settings</div>
        <div v-if="userRights < 100" :class="{'active': activeTab == 'history'}" @click="switchTab('history')">History</div>
        <div v-if="userRights == 125" :class="{'active': activeTab == 'articles'}" @click="switchTab('articles')">Articles</div>
        <div v-if="userRights == 125" :class="{'active': activeTab == 'сourses'}" @click="switchTab('сourses')">Currency</div>
        <div v-if="userRights == 125" :class="{'active': activeTab == 'stock'}" @click="switchTab('stock')">Stock</div>
        <div v-if="userRights == 125" :class="{'active': activeTab == 'stock-forecast'}" @click="switchTab('stock-forecast')">Stock forecast</div>
        <div class="user-ip">Your ip: {{$store.state.userIp}}</div>
    </div> 
</template>

<script>
    export default {
        data() {
            return {
                active: 'trade'
            }
        },
        props: ['userRights', 'activeTab'],
        methods: {
            switchTab(tab) {
                this.$emit('switchTab', tab);
            }
        }
    }
</script>