<template>
    <div class="center">
        <div class="article internal fake">
            <div class="currency managing">
                <div class="currency__course">
                    <div class="currency-single"><div class="currency-label usd">USD</div> <input v-model="currency.dollar.course"></div>
                    <div class="currency-single"><div class="currency-label euro">EURO</div> <input v-model="currency.euro.course"></div>
                    <div class="currency-single"><div class="currency-label aed">AED</div> <input v-model="currency.aed.course"></div>
                    <div class="currency-single"><div class="currency-label cny">CNY</div> <input v-model="currency.cny.course"></div>
                </div>
                <transition name="fade">
                    <div class="success" v-if="coursesSaved">
                        Changes are saved
                    </div>
                </transition>  
                <div class="currency-save" @click="saveCurrency">Save</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            currency: {
                dollar: {},
                euro: {},
                cny: {},
                aed: {}
            },
            coursesSaved: false,
            firstLoad: true
        }
    },
    methods: {
        loadCurrency() {
            this.$store.state.currencyData.forEach(c => {
                if (c.name === 'dollar') {
                    this.currency.dollar = c;
                } else if (c.name === 'euro') {
                    this.currency.euro = c;
                } else if (c.name === 'aed') {
                    this.currency.aed = c;
                } else if (c.name === 'cny') {
                    this.currency.cny = c;
                }
            })
            if (!this.firstLoad) {
                this.coursesSaved = true;
            }
            this.firstLoad = false;
            setTimeout(() => {
                this.coursesSaved = false
            }, 2000)
        },
        saveCurrency() {
            this.$store.dispatch('saveCurrencyData', [this.currency.dollar.course, this.currency.euro.course, this.currency.aed.course, this.currency.cny.course]);
        }
    },
    mounted() {
        this.$store.dispatch('currencyData');
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'SET_CURRENCY_DATA') {
                this.loadCurrency();
            }
        })
    }
}
</script>