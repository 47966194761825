<template>
    <div class="login__user wide" v-if="displayCabinet">
        <template v-if="$store.state.user.status == 0">
            <h3 style="margin: 30px 0 0;">Your email is not approved</h3>
        </template>
        <template v-else-if="$store.state.user.status == 1">
            <h3 style="margin: 30px 0 0;">You have no access or blocked</h3>
        </template>
        <template v-else-if="$store.state.user.status > 1">
            <div class="personal-header">
                <h1>{{pageHeader}}</h1>
            </div>    
                    
            <personal-menu 
                :userRights="$store.state.user.rights"
                :activeTab="active"
                @switchTab="switchTab"/>

            <transition name="fade" mode="out-in">
                <div key="1" class="trade" v-if="active === 'trade'">
                    <div class="personal-header" v-if="$store.state.user.status == 4">
                        <p class="personal-balance"><span>Balance:</span><span>Wallet: {{$store.state.user.wallet}}</span></p>
                        <div><span>{{$store.state.user.balance}} (<span style="color:#F5BC00; border: none;">{{$store.state.user.frozen}}</span>) CRON</span>
                        <template v-if="false">
                            <span>{{$store.state.user.mBalance}} (<span style="color:#F5BC00; border: none;">{{$store.state.user.mFrozen}}</span>) mCRON</span>
                        </template>
                        </div>
                    </div>
                    <form class="verification" v-if="$store.state.user.status == 2">
                        <div v-if="verification == false">
                            <p>Your identity is not verified and you cannot carry out transactions.</p>
                            <p>To verify, please, send us photo or scan of your documents, confirmed your profile information.</p>
                            <div class="proceed-verification submit" @click="proccedVerification">Proceed verification</div>
                        </div>   
                        <div v-if="verification === true">
                            <p style="text-align: center">Attach files in png, jpg, jpeg or pdf, up to 25 Mb</p>
                            <div class="file-wrapper">
                                <input id="files" type="file" ref="file" multiple="multiple" placeholder="Upload files" name="files" @change="selectFiles">
                                <div v-if="this.files.length > 0">You have selected {{files.length}} file(-s)</div>
                                <label for="files" class="label-file">{{files.length > 0 ? 'Change files' : 'Select files'}}</label>
                            </div>
                            <div class="errors" v-if="displayErrors">
                                <div class="error" v-for="(error, index) in registerErrors" :key="index">
                                    {{error.message}}
                                </div>
                            </div>
                            <div class="proceed-verification submit" @click="sendVerification" :class="{'loginInProgress': verificationInProgress}">Send</div>
                        </div>                     
                    </form>
                    <div class="verification verififcationSucceed" v-if="$store.state.user.status == 3">
                        <div v-if="verification == false" style="text-align: center; line-height: 25px;">
                            <p>You successfully applied for profile verification.<br/>Please, wait until we look through documents.</p>
                        </div>                     
                    </div>
                    <form v-if="$store.state.user.status == 4">
                        <p style="text-"><b>"International Trading Platform"</b> is ready to be launched.<br/>We will notify you as soon as we collect all the required permissions of supervisory authorities .</p>
                        <p>Until that moment you are absolutely free to reach us out on any questions regarding purchasing, reselling and<br>investments of <b>"Climate Cron"</b>.</p>
                        <p>
                            <a href="tel:+971585430133" style="color:#144E8C; text-decoration: none;">+(971) 585430133</a> - Customer Service<br>
                            <a href="https://wa.me/:+971526851133" style="color:#144E8C; text-decoration: none;">+(971) 526851133</a> - Whatsapp
                        </p>
                        <!-- <div class="trade-header">
                            <h3 @click="switchTrade('sell')" :class="{'active': activeTrade === 'sell'}">Sell</h3>
                            <h3 @click="switchTrade('buy')" :class="{'active': activeTrade === 'buy'}">Buy</h3>
                            <h3 @click="switchTrade('orders')" :class="{'active': activeTrade === 'orders'}">My orders</h3>
                        </div> -->
                        <!-- <div :key="1" v-show="activeTrade === 'sell'" class="trade-body">
                            <sell-cron :balance="this.$store.state.user.balance" :mBalance="this.$store.state.user.mBalance"></sell-cron>
                        </div>
                        <div :key="2" v-show="activeTrade === 'buy'" class="trade-body">
                            <template v-if="false">
                                <p style="text-align: center; display: flex; justify-content: center; font-size: 16px; font-weight: 300;">
                                    <span style="border-bottom: 1px solid #ccc; ">Bank details "Climate Global Control Trading LLC"</span>
                                </p>
                                <p class="custom-info">
                                    <span style="display: flex;">
                                        <span>Account №:</span>1851323559698010
                                    </span>
                                    <span style="display: flex;">
                                        <span>IBAN:</span>AE830351851323559698010
                                    </span>
                                    <span style="display: flex;">
                                        <span>Account currency:</span>UAE Dirham
                                    </span>
                                    <span style="display: flex;">
                                        <span>Swift code:</span>NBADAEAA
                                    </span>
                                </p>
                            </template>
                            <buy-cron v-if="false"></buy-cron>
                            <orders :type="2" :user_id="null" :title="'Available CRON for sale on market'" :guest="false"></orders>
                        </div>
                        <div :key="3" v-show="activeTrade === 'orders'" class="trade-body">
                            <orders :type="2" :user_id="$store.state.user.id" :title="'Available CRON for sale'" :guest="false"></orders>
                        </div> -->
                    </form>
                </div>
                <div key="2" class="settings" v-if="active === 'settings'">
                    <settings></settings>
                </div>
                <div key="3" class="settings" v-if="active === 'history'">
                    <h3>History of transactions</h3>
                    <balance-history></balance-history>
                </div>
                <div key="4" class="settings" v-if="active === 'stock'">
                    <h3>Stock data</h3>
                    <stock></stock>
                </div>
                <div key="5" class="settings" v-if="active === 'stock-forecast'">
                    <h3>Forecast data</h3>
                    <stock-forecast></stock-forecast>
                </div>
                <div key="6" class="settings" v-if="active === 'сourses'">
                    <h3>Currency settings</h3>
                    <courses></courses>
                    <div class="separator"></div>
                    <h3>Market settings</h3>
                    <market></market>
                </div>
                <div key="7" class="settings" v-if="active === 'articles'">
                    <h3>Articles</h3>
                    <articles></articles>
                </div>
                <div key="8" class="settings" v-if="active === 'sell'">
                    <sell-cron></sell-cron>
                </div>
            </transition>
        </template>
    </div>    
</template>
<script>
import axios from 'axios';
import Stock from './Stock.vue';
import StockForecast from './StockForecast.vue';
import Courses from './Courses.vue';
import Market from './Market.vue';
import Articles from '../main/Articles.vue'
import SellCron from './SellCron.vue'
// import BuyCron from './BuyCron.vue'
// import Orders from './Orders.vue';
import BalanceHistory from './BalanceHistory.vue';
import Settings from './Settings.vue';
import PersonalMenu from './PersonalMenu.vue';
export default {
    data() {
        return {
            verification: false,
            pageHeader: this.$store.state.user.rights < 100 ? 'My account':'Admin panel',
            active: this.$store.state.user.rights != 125 ? 'trade' : 'articles',
            activeTrade: 'sell',
            dollar: 0,
            displayCabinet: false,           
            files: []
        }
    },
    components: {
        'stock': Stock,
        'courses': Courses,
        'market': Market,
        'stock-forecast': StockForecast,
        'articles': Articles,
        'sell-cron': SellCron,
        // 'buy-cron': BuyCron,
        // 'orders': Orders,
        'settings': Settings,
        'balance-history': BalanceHistory,
        'personal-menu': PersonalMenu
    },
    methods: {
        switchTab: function(type) {
            this.active = type;
            this.userSaved = false;
            this.displayErrors = false;
        },
        switchTrade: function(type) {
            this.activeTrade = type;
        },      
        selectFiles(e) {
            this.files = e.target.files;
        },
        proccedVerification() {
            this.verification = true;
        },
        sendVerification() {
            if (this.verificationInProgress === true || this.$refs['file'].files.length === 0) {
                return false;
            }
            this.files = this.$refs['file'].files;
            let formData = new FormData();
            formData.append('action', 'verification');
            formData.append('user_id', this.$store.state.user.id);
            for (let i = 0; i < this.files.length; i++){
                let file = this.files[i];
                formData.append('files[' + i + ']', file);
            }
            this.verificationInProgress = true;
            axios.post(this.$store.state.postUrl, formData, {headers: {
                'Content-Type': 'multipart/form-data'
            },}).then(response => {
                if (response.data == 'ok') {
                    this.$router.go();
                } else {
                    this.verificationInProgress = false;
                    this.registerErrors = [];
                    this.registerErrors.push({type: 'name', message: "You uploaded wrong files format\nor they are to big."});
                    this.displayErrors = true;
                    setTimeout(() => {
                        this.displayErrors = false
                    }, 3000)
                }
            }).catch(e => {
                console.error(e + '\nSome error occured, please, reload the page or contact support')
            })
        }
    },
    mounted() {
        this.$store.dispatch('updateBalance');
        if (!this.$store.state.user.status) {
            if (this.$router.currentRoute.name !== "login") { 
                this.$router.push({ name: 'login'})
            }                    
        } else {
            this.displayCabinet = true;
        }
        axios.post(this.$store.state.postUrl, {
            action: 'getCurrencyData',
            currency: 'dollar'
        }, this.$store.state.config).then(response => {
            this.dollar = response.data[0].course;
        }).catch(e => {
            console.error(e + '\nSome error occured, please, reload the page or contact support')
        })
    }
}
</script>