<template>
    <div>
        <div class="balance-log" v-for="(log, index) in balanceData" :key="index" :class="{'deposit':log.type == 1, 'withdraw': log.type == 2}">
            <div>{{log.type == 1 ? '+' : '-'}}{{log.coins}}<br/><span>{{log.coins_type == 2 ? 'mCRON' : 'CRON'}}</span></div>
            <div>{{log.price}}$</div>
            <div>{{log.date | moment("DD/MM/YYYY")}}</div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() { 
        return {
            balanceData: []
        }
    },
    methods: {
        getHistory() {
            axios.post(this.$store.state.postUrl, {
                action: 'getBalanceHistory',
            }, this.$store.state.config).then(response => {
                this.balanceData = response.data;
            }).catch(e => {
                console.error(e + '\nSome error occured, please, reload the page or contact support')
            })
        }
    },
    created() {
        this.getHistory();
    }
}
</script>