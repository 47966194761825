<template>
    <div class="article internal">
        <h1>{{page}}</h1>
        <p><img src="/media/concept/1.jpg"></p>
        <p><img src="/media/concept/2.jpg"></p>
        <p><img src="/media/concept/3.jpg"></p>
        <p><img src="/media/concept/4.jpg"></p>
        <p><img src="/media/concept/5.jpg"></p>
        <p><img src="/media/concept/6.jpg"></p>
        <p><img src="/media/concept/7.jpg"></p>
        <p><img src="/media/concept/8.jpg"></p>
        <p><img src="/media/concept/9.jpg"></p>
        <p><img src="/media/concept/10.jpg"></p>
        <p><img src="/media/concept/11.jpg"></p>
        <p><img src="/media/concept/12.jpg"></p>
        <p><img src="/media/concept/13.jpg"></p>
        <p><img src="/media/concept/14.jpg"></p>
        <p><img src="/media/concept/15.jpg"></p>
        <p><img src="/media/concept/16.jpg"></p>
        <p><img src="/media/concept/17.jpg"></p>
        <p><img src="/media/concept/18.jpg"></p>
        <p><img src="/media/concept/19.jpg"></p>
        <p><img src="/media/concept/20.jpg"></p>
        <p><img src="/media/concept/21.jpg"></p>
        <p><img src="/media/concept/22.jpg"></p>
        <p><img src="/media/concept/23.jpg"></p>
        <p><img src="/media/concept/24.jpg"></p>
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page: "The Concept of Monetization and Marketing of Global Market of Water Resources by means of “CLIMATE CRON”"
        }
    }
}
</script>